import React from 'react';
import './square-banner.styles.scss';
import { apiBaseUrl } from '../../utilities/config';

const SquareBanner = ({name, img_url, classType}) => 
{
    return(
        <div className={`square-banner ${classType}`}>
            <div className="background-image" 
                style={{
                    'backgroundImage': `url(${apiBaseUrl + img_url})`
                }}
            />
            <div className={`content ${classType}`}>
                <h3 className='name'>{name}</h3>
            </div>
        </div>
    );
}

export default SquareBanner;