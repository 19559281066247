import websiteReducer from "./website/website.reducer";
import budgetReducer from "./budget/budget.reducer";
import modalReducer from "./modal/modal.reducer";
import { combineReducers } from 'redux';

export default combineReducers({
  website: websiteReducer,
  budget: budgetReducer,
  modal: modalReducer
});

