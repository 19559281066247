import React from 'react';
import './language-change.styles.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SUPPORTED_CURRENCIES } from '../../utilities/utility';
import { connect } from 'react-redux';
import Flag from 'react-flagkit';

import { useTranslation } from 'react-i18next';

const LanguageChange = (props) => {
    const {t} = useTranslation();
    return (
        <div className="card language-change">
            <div className="language-header">
                <a onClick={() => props.toggleModal({ action: `close` })}><FontAwesomeIcon icon="times" /></a>
            </div>
            <div className="card-content">
                <div className="content">
                    <h3>
                        {t('languages.title')}
                    </h3>
                    <p>{t('languages.subtitle')}</p>
                    <br/>
                    <div className="row">
                        {Object.entries(SUPPORTED_CURRENCIES).map((object) => {
                            return (
                                <div className="col-md-6">
                                    <div 
                                        className={`currency-wrapper ${props.seen_language === object[0] ? 'selected' : '' }`}
                                        onClick={() => {props.setLanguage(object[0]); props.toggleModal({action: `close`});}}
                                    >
                                        <div className="title">{object[1].name} - <Flag country={object[1].flag}/></div>
                                        <div className="subtitle">{object[1].country} </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        seen_language: state.website.seen_language
    }
}
const mapDispatchToProps = dispatch => {
    return {
        setLanguage: (language) => dispatch({ type: "SET_LANGUAGE", payload: { language: language } }),
        toggleModal: (action) => dispatch({ type: "TOGGLE_MODAL", payload: { action: action.action, children: action.children } })
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(LanguageChange);
