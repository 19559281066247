import { updateObject } from '../../utilities/utility';
import moment from 'moment';
import i18n from '../../i18n';

const initialState = {
  categories: [],
  cities: [],
  extras: [],
  isLogged: false,
  duration: 5,
  selectedCategory: '',
  modal_open: false,
  promotion: false,
  referral: null,
  exchange_currency: 'USDT',
  currency_hash: '',
  uiMode: 'light',
  seen_language: 'ptBR'
}

const LOCATION_SELECTS = {
	origin: 'origin_location',
	destination: 'destination_location'	
}

const DATE_SELECTS = {
	startDate: 'begin_date',
	endDate: 'end_date'	
}

const fillCategories = (state, action) => {
    return updateObject(state, {categories: action.payload});
}

const setExtras = (state, action) => {
	return updateObject(state, {extras: action.payload});
}

const setLanguage = (state, action) => {
	i18n.changeLanguage(action.payload.language);
	return updateObject(state, {seen_language: action.payload.language});
}

const fillCities = (state, action) => {
	let cityMap = [];
	Object.values(action.payload).map(ele => {
	  return (cityMap[ele.id] = {
		name: ele.name,
		id: ele.id,
		img_url: ele.img_url,
		surcharge: ele.taxa_diaria,
		rent_tax: ele.taxa_aluguel,
		sales_tax: ele.tax
	  });
	});
    return updateObject(state, {cities: cityMap});
}

const setHash = (state, action) => {
    return updateObject(state, {currency_hash: action.payload.currency_hash});
}

const setCity = (state, action) => {
	let newState            = {};
	let stateToUpdate       = LOCATION_SELECTS[action.payload.origin];
	
	newState[stateToUpdate] = action.payload.value;

	if (stateToUpdate === 'origin_location') {
		newState.destination_location = action.payload.value;
	}
	return updateObject(state, newState);
}

const login = (state, action) => {
	return updateObject(state, {isLogged: true});

}

const logout = (state, action) => {
	return updateObject(state, {isLogged: false});

}

const setDate = (state, action) => {
	let newState            = {};
	let stateToUpdate       = DATE_SELECTS[action.payload.origin];

	newState[stateToUpdate] = action.payload.date;
	
	if (stateToUpdate === 'begin_date') {
		newState.end_date = moment(action.payload.date).add(5, 'days').toDate();
	}

	newState = updateObject(state, newState);
	let begin_date = moment(newState.begin_date);
	let end_date   = moment(newState.end_date);
	begin_date.set({hour:0, minute: 0, second: 0});
	end_date.set({hour:23, minute: 59, second: 59});

	let duration = moment.duration(end_date.diff(begin_date, 'days'), 'days').asDays();
	if (duration === 0) {
		duration = 1;
	}

	newState = {
		...newState,
		duration: duration
	}

	return updateObject(state, newState);
}

const setReferral = (state, action) => {
	return updateObject(state, {referral: action.ref});
}

const toggleModal = (state, data) => {
	let modalAction = data.payload.action;
	let modalContent = '';
	if (modalAction === 'close') {
		modalAction  = false;
		modalContent = '';
	} else {
		modalAction = true;
		modalContent = data.payload.children;
	}

	return updateObject(state, {modal_open: modalAction, modal_content: modalContent});
}

const setPromotion = (state, action) => {
    return updateObject(state, {promotion: action.promotion});
}
const websiteReducer = (state = initialState, action) => { 
 	switch ( action.type ) {
 		case 'SEARCH_VEHICLES': return {state};
 		case 'SET_CITY': return setCity(state, action);
 		case 'SET_DATE': return setDate(state, action);
 		case 'TOGGLE_MODAL': return toggleModal(state, action);
 		case 'SET_PROMOTION': return setPromotion(state, action);
 		case 'SET_REFERRAL': return setReferral(state, action);
		case 'CURRENCY_HASH': return setHash(state, action);
		case 'SET_LANGUAGE': return setLanguage(state, action); 
 		case 'LOGIN': return login(state, action);
 		case 'LOGOUT': return logout(state, action);
 		case 'FILL_CITIES': return fillCities(state, action);
		case 'FILL_CATEGORIES': return fillCategories(state, action);
		case 'SET_EXTRAS': return setExtras(state, action); 
 		default: return state;
 	}
};

export default websiteReducer;
