import React from 'react';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import { apiBaseUrl } from '../../utilities/config';
import './vehicle-card.styles.scss';
import { useTranslation } from 'react-i18next';

const VehicleCard  = ({car_category, linkTo, handleClick, duration}) => {
  
  const {t} = useTranslation();

  return (
  <Card className="h-100 vehicle-card">
    <Card.Img variant="top" src={apiBaseUrl + car_category.img_url}/>
    <Card.Body className="">
      <Card.Title>{car_category.website_title}</Card.Title>
      <h2 className="subtitle">{car_category.website_subtitle}</h2> 
      <Card.Text>
      {car_category.gearbox == 0 
        ? t('budget.category.description-manual', {'category': car_category}) 
        : t('budget.category.description-automatic', {'category': car_category})
      } 
      <div className="prices">
        <div className="daily">
          <b>${parseFloat(car_category.diaria).toFixed(2).replace(',', '').replace('.', ',')}</b>/{t('budget.search.daily')}
        </div>
        <div className="total">
        {t('budget.search.total', {total: (parseFloat(car_category.diaria) * duration).toFixed(2).replace(',', '').replace('.', ',')})}
        </div>
      </div>
      </Card.Text>
      </Card.Body>
    </Card>
);}

export default VehicleCard;