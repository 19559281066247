import React from 'react';
import { importAll } from '../../utilities/utility';
import './landing.styles.scss';
import SearchBox from '../../components/search-box/search-box.component';
import { Slide, Fade } from 'react-slideshow-image';
import { withTranslation } from 'react-i18next';
const properties = {
    duration: 5000,
    transitionDuration: 1000,
    infinite: true,
    indicators: false,
    pauseOnHover: false,
    arrows: false,
    onChange: (oldIndex, newIndex) => {
    }
  };
class Landing extends React.Component {
    constructor(props) {
        super(props);
        const images = importAll(require.context('../../assets/backgrounds', false, /\.(png|jpe?g|svg)$/));
        this.state = {
            images: images
        }
    }
    render() {
        const {t} = this.props;
        return (
            <section className="landing">
                <div className="slider-container">
                    <Fade {...properties}>
                        <div className="each-slide">
                            <div 
                                className="cycling-image d-flex"
                                style={{
                                    background: `url(${this.state.images['background_01.jpg']})`,   
                                    alignItems: 'flex-end'
                            }}>
                            </div>
                        </div>
                        <div className="each-slide">
                            <div 
                                className="cycling-image d-flex"
                                style={{
                                    background: `url(${this.state.images['background_02.jpg']})`,   
                                    alignItems: 'flex-end'
                            }}>
                            </div>
                        </div>
                        <div className="each-slide">
                            <div 
                                className="cycling-image d-flex"
                                style={{
                                    background: `url(${this.state.images['background_03.jpg']})`,   
                                    alignItems: 'flex-end'
                            }}>
                            </div>
                        </div>
                    </Fade>
                </div>
                <h2 className="d-block d-sm-block d-md-none text-left" style={{color: '#000', paddingLeft: '1rem', paddingRight: '1rem', marginTop: '1rem'}}>{t('main.call')}</h2>
                <SearchBox />
            </section>
        )
    }
}

export default withTranslation()(Landing);