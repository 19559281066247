import React from 'react';
import './personal-information.styles.scss';
import { apiBaseUrl } from '../../utilities/config';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import FormInput from '../../components/form-input/form-input.component';
import DatepickerFormInput from '../../components/datepicker-form-input/datepicker-form-input.component';
import Terms from '../../components/terms/terms.component';
import CustomButton from '../../components/custom-button/custom-button.component';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withTranslation } from 'react-i18next';
import {
  SingleDatePicker
} from "react-dates";
import { NavHashLink as NavLink } from 'react-router-hash-link';

import { Api } from '../../services/api';
import moment from 'moment';

class PersonalInformation extends React.Component {
  constructor(props) {
      super(props);
      this.state = {
        first_name: '',
        last_name: '',
        phone: '',
        whatsapp: '',
        email: '',
        driver_license: '',
        nationality: 2,
        travel_company: '',
        flight_number: '',
        terms: true,
        accepted: false,
        hasFlight: false,
        loading: false,
        driver_license_expiry: '',
        birthday: '',
        address: '',
        fullPaymentSelected: 'selected',
        installmentSelected: ''
      }
  }
  componentDidMount() {
    this.props.step({
        step: 2
    });
  }
  enableButton = () => {
      let currentState = {...this.state};
      if (
        currentState.name != '' &&
        currentState.phone != '' &&
        currentState.whatsapp != '' &&
        currentState.email != '' &&
        currentState.driver_license != '' &&
        currentState.accepted == true
      ) {
        return true;
      }
  
      return false;
    }
  handleChange = event => {
      const { value, name } = event.target;

      this.setState({ [name]: value });
  };
  handleChangeDate = (event, name) => {
      this.setState({[name]: event});
  };
  selectPaymentMethod = method => {
    let fullPaymentSelected = "";
    let installmentSelected = "";
    if (method === 1) {
        fullPaymentSelected = "selected";
    }
    if (method === 2) {
        installmentSelected = "selected";
    }
    this.setState({
        fullPaymentSelected,
        installmentSelected,
    });
  };
  
  prepareBudget = () => {
    let payment_type = this.state.fullPaymentSelected !== "" ? 1 : 2; 
    let clientInformation = {

      first_name: this.state.first_name,
      last_name: this.state.last_name,
      phone: this.state.phone,
      whatsapp: this.state.whatsapp,
      email: this.state.email,
      driver_license: this.state.driver_license,
      driver_license_expiry: this.state.driver_license_expiry.format('YYYY-MM-DD'),
      address: this.state.address,
      birthday: this.state.birthday.format('YYYY-MM-DD'),
      nationality: this.state.nationality,
      flight_number: this.state.flight_number,
      travel_company: this.state.travel_company,
      payment_type
    };
    this.props.postBudget(clientInformation);
  }

  render() {
      const {installmentSelected, fullPaymentSelected, reservationEnabled, windowWidth} = this.state;
      const {t}= this.props;
      let payFull  = t('checkout-form.short-full');
      let bookText = t('checkout-form.short-installments');    
      return (
          <div>
          <form onSubmit={this.handleSubmit}>
          <div className="customer-form form-box">
              <h1 className="title">{t('checkout-form.personal-information')}</h1>
              <Form.Row>
                      <Form.Group as={Col} md={6} sm={12} controlId="formGridEmail">
                          <FormInput
                              name='first_name'
                              type='text'
                              handleChange={this.handleChange}
                              value={this.state.first_name}
                              label={t('checkout-form.first_name')}
                              required
                          />
                      </Form.Group>
                      <Form.Group as={Col} md={6} sm={12} controlId="formGridEmail">
                          <FormInput
                              name='last_name'
                              type='text'
                              handleChange={this.handleChange}
                              value={this.state.last_name}
                              label={t('checkout-form.last_name')}
                              required
                          />
                      </Form.Group>
                      <Form.Group as={Col} md={4} sm={12} controlId="formGridEmail">
                          <FormInput
                              name='email'
                              type='email'
                              handleChange={this.handleChange}
                              value={this.state.email}
                              label={t('checkout-form.email')}
                              required
                          />
                      </Form.Group>
                      <Form.Group as={Col} md={4} sm={12} controlId="formGridEmail">
                          <FormInput
                              name='phone'
                              type='text'
                              handleChange={this.handleChange}
                              value={this.state.phone}
                              label={t('checkout-form.phone')}
                              required
                          />
                      </Form.Group>
                      <Form.Group as={Col} md={4} sm={12} controlId="formGridEmail">
                          <FormInput
                              name='whatsapp'
                              type='text'
                              handleChange={this.handleChange}
                              value={this.state.whatsapp}
                              label={t('checkout-form.whatsapp')}
                              required
                          />
                      </Form.Group>
                      <Form.Group as={Col} md={12} sm={12} controlId="formGridEmail">
                          <FormInput
                              name='address'
                              type='text'
                              handleChange={this.handleChange}
                              value={this.state.address}
                              label={t('checkout-form.address')}
                              max="255"
                              required
                          />
                      </Form.Group>
              
                      <Form.Group as={Col} md={6} sm={12} controlId="formGridEmail">
                        <DatepickerFormInput 
                          label={t('checkout-form.birthday')}
                          date={this.state.birthday}
                          name="birthday"
                          handleChange={this.handleChangeDate}
                        />
                      </Form.Group>
                      <Form.Group as={Col} md={6} sm={12} controlId="formGridEmail">
                        <Form.Label>{t('checkout-form.nationality')}</Form.Label>
                          <Form.Control 
                              as="select"
                              name="nationality"
                              placeholder={t('checkout-form.nationality')}
                              value={this.state.nationality} 
                              onChange={this.handleChange}
                          >
                              <option value="2">{t('checkout-form.nationality.american')}</option>
                              <option value="1">{t('checkout-form.nationality.brazilian')}</option>
                              <option value="0">{t('checkout-form.nationality.other')}</option>
                          </Form.Control>
                      </Form.Group>
                  </Form.Row>
                  <Form.Row>
                      <Form.Group as={Col} md={6} sm={12} controlId="formGridEmail">
                          <FormInput
                              name='driver_license'
                              type='text'
                              handleChange={this.handleChange}
                              value={this.state.driver_license}
                              label={t('checkout-form.driver_license')}
                              required
                          />
                      </Form.Group>
                      <Form.Group as={Col} md={6} sm={12} controlId="formGridEmail">
                        <DatepickerFormInput 
                          label={t('checkout-form.driver_license_expiry')}
                          date={this.state.driver_license_expiry}
                          name="driver_license_expiry"
                          handleChange={this.handleChangeDate}
                        />
                      </Form.Group>
                  </Form.Row>
                  <h1 className="title">{t('checkout-form.flight_information')}</h1>
                  <Form.Row>
                              
                      <Form.Group as={Col} md={6} sm={12} controlId="formGridEmail">
                          <FormInput
                              name='travel_company'
                              type='text'
                              handleChange={this.handleChange}
                              value={this.state.travel_company}
                              label={t('checkout-form.travel_company')}
                          />
                      </Form.Group>         
                              
                      <Form.Group as={Col} md={6} sm={12} controlId="formGridEmail">
                          <FormInput
                              name='flight_number'
                              type='text'
                              handleChange={this.handleChange}
                              value={this.state.flight_number}
                              label={t('checkout-form.flight_number')}
                          />
                      </Form.Group>
                      </Form.Row>
                      <h1 className="title">{t('checkout-form.payment.title')}</h1>
                      <h2 className="subtitle">{t('checkout-form.payment.subtitle')}</h2>
                      <br/>
                      <div class="switch-container">
                        <div className="switch-div input-group">
                          <div className="input-group-prepend">
                              <button
                                type="button"
                                className={`btn switch-button ${fullPaymentSelected}`}
                                onClick={() => this.selectPaymentMethod(1)}
                              >
                                <p className="switch-text">{payFull}</p>
                              </button>
                          </div>
                          <div class="input-group-append">
                            <button
                              type="button"
                              className={`btn switch-button ${installmentSelected}`}
                              onClick={() => this.selectPaymentMethod(2)}
                            >
                              <p className="switch-text">{bookText}</p>
                            </button>
                          </div>
                        </div>
                      </div>
                      <Row className="switch-labels text-center">
                      <Col className="switch-label"><label className={`label ${fullPaymentSelected}`}>{t('checkout-form.payment.full')}</label></Col>
                      <Col className="switch-label"><label className={`label ${installmentSelected}`}>{t('checkout-form.payment.installments')}</label></Col>
                      </Row>
                      <div className="content is-small terms">
                  <a className="link" onClick={() => this.setState({ terms: !(this.state.terms) })}>{t('checkout-form.terms')} <FontAwesomeIcon icon="angle-down" /></a>
                          <div className={`${
                              !this.state.terms ? 'visible' : ''
                            } terms-box`}>
                              <Terms />
                          </div>
                          <div className="checkbox-wrapper">
                          <button type="button" id="accept" className={"checkbox " + ((this.state.accepted) ? 'is-active' : '')} onClick={() => this.setState({accepted: !(this.state.accepted)})}>
                          {(this.state.accepted)
                              ? <span className="check"><FontAwesomeIcon icon="check" /></span>
                              : ''
                          }
                          </button> <label htmlFor="accept">{t('checkout-form.terms-accept')}</label>
                          </div>
                      </div>
                      <div className="finish-wrapper">
                        <button type="button" className="button finish" disabled={!this.enableButton()} onClick={() => this.prepareBudget()}>{t('checkout-form.confirm')}</button>
                        <p hidden={this.enableButton()}> {t('checkout-form.required-fields')}</p>
                      </div>
                  </div>
          </form>
          </div>
      )
  }
}

const mapStateToProps = ({website, budget}) => ({
  car_category: budget.car_category,
  origin_location: budget.origin_location,
  destination_location: budget.destination_location,
  begin_date: budget.begin_date,
  end_date: budget.end_date,
  duration: budget.duration,
  selectedExtras: budget.selectedExtras,
  cities: website.cities,
  exchange_currency: website.exchange_currency,
  currency_hash: website.currency_hash,
  extras: website.extras
});

const mapDispatchToProps = (dispatch) => ({
  setHash: (payload) => dispatch({type: 'CURRENCY_HASH', payload: payload}),
  step: (payload) => dispatch({type: 'STEP', payload: payload})
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(PersonalInformation));