import React from 'react';
import './location.styles.scss';

import { withTranslation } from 'react-i18next';

import ContentWithImage from '../../components/content-with-image/content-with-image.component';
import { companyInfo } from '../../utilities/content/company-content';

const Location = ({t}) => {
  return (
    <section className="location-section">
        <h3 className="text-center">{t('location.title')}</h3>
        <ContentWithImage {...companyInfo[1]} direction='top' hasMap={true}/>
    </section>
  );
};

export default withTranslation()(Location);
