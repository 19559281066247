import React from 'react';

import './datepicker-form-input.styles.scss';
import { SingleDatePicker } from 'react-dates';
import moment from 'moment';
class FormInput extends React.Component{
  constructor(props) {
      super(props);
      this.state = {};
  }

  returnYears = () => {
        let years = []
        for(let i = moment().year() - 100; i <= moment().add('10', 'years').year(); i++) {
            years.push(<option value={i}>{i}</option>);
        }
        return years;
    }

  renderMonthElement = ({ month, onMonthSelect, onYearSelect }) =>
    <div style={{ display: 'flex', justifyContent: 'center' }}>
        <div>
            <select
                value={month.month()}
                onChange={(e) => onMonthSelect(month, e.target.value)}
            >
                {moment.months().map((label, value) => (
                    <option value={value}>{label}</option>
                ))}
            </select>
        </div>
        <div>
            <select value={month.year()} onChange={(e) => onYearSelect(month, e.target.value)}>
                {this.returnYears()}
            </select>
        </div>
    </div>

  render() {
    const { handleChange, label, mask, ...otherProps} = this.props;
    return (
      <div className='group'>
        <SingleDatePicker 
          date={this.props.date} 
          name={this.props.name}
          onDateChange={(event) => handleChange(event, this.props.name)} 
          focused={this.state.focused}
          placeholder=""
          onFocusChange={({ focused }) => this.setState({ focused })}
          id={this.props.name}
          renderMonthElement={this.renderMonthElement}
          block={true}
          isOutsideRange={() => false}
          noBorder={true}
        />
        {label ? (
          <label
            className={`${
              this.props.date ? 'shrink' : ''
            } form-input-label`}
          >
            {label}
          </label>
        ) : null}
      </div>
    );
  }
}

export default FormInput;
