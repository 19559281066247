import React from 'react';
import {Switch, Route} from 'react-router-dom';
import './App.scss';

import Homepage from './pages/homepage/homepage.component';
import Authentication from './pages/authentication/authentication.component';
import Faq from './pages/faq/faq.component';
import Search from './pages/search/search.component';
import BudgetConfiguration from './components/budget-configuration/budget-configuration.component';
import ReviewAndPay from './pages/review-and-pay/review-and-pay';
import { Api } from './services/api';
import { connect } from 'react-redux';
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from '@fortawesome/free-brands-svg-icons';
import { fas } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import LanguageChange from './components/language-change/language-change.component';

import Modal from './components/modal/modal.component';
import "react-dates/initialize";

library.add(fab, fas, far);
class App extends React.Component {
  componentDidMount() {
    Api.get(`cars/categories`)
    .then(response => {
      if (response.status == 200) {
        this.props.fillCategories(response.data);
      }
    });
    
    Api.get(`cities`, {
      params: {
        exchange_currency: this.props.exchange_currency,
        currency_hash: this.props.currency_hash
      }
    })
    .then(response => {
      if (response.status == 200) {
        this.props.fillCities(response.data.cities);
      }
    });
    this.props.toggleModal(
      { action: `open`, 
      children: 
      <LanguageChange
      /> }
    );
  }
  
  render() {
    return (
      <React.Fragment>
        <Modal show={this.props.modal_state} children={this.props.modal_content} handleClose={() => this.props.toggleModal({action: `close`})}/>
        <div className="App">
          <Switch>
            <Route exact path="/" component={Homepage}/>
            <Route path="/faq" component={Faq}/>
            <Route path="/s" component={Search} />
            <Route path="/order/review/" component={ReviewAndPay} />
            <Route path="/budget/" component={BudgetConfiguration} />
          </Switch>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    modal_state: state.website.modal_open,
    modal_content: state.website.modal_content,
    exchange_currency: state.website.exchange_currency,
    currency_hash: state.website.currency_hash
  }
}

const mapDispatchToProps = dispatch => {
  return {
    toggleModal: (action) => dispatch({type: "TOGGLE_MODAL", payload: {action: action.action, children: action.children}}),
    fillCategories: (categories) => dispatch({type: 'FILL_CATEGORIES', payload: categories}),
    fillCities: (cities) => dispatch({type: 'FILL_CITIES', payload: cities})
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
