import React, { Component } from "react";
import { connect } from "react-redux";
import Confirmed from "../../assets/icons/Confirmado.png";
import moment from 'moment';
import { Api } from '../../services/api';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {apiBaseUrl} from "../../utilities/config";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NavHashLink as NavLink } from 'react-router-hash-link';
import './review-and-pay.scss';
import Budget from '../../components/budget/budget.component';
import PaymentConfirmation from '../payment-confirmation/payment-confirmation.component';
import { withTranslation, Trans } from 'react-i18next';
import { withRouter, Redirect } from 'react-router-dom';

class ReviewAndPay extends Component {
  constructor(props) {
    super(props);
    this.state = {
        payment_url: ''
    };
  }

  componentDidMount() {
    this.props.step({
        step: 3
    });
  }

  render(props) {
    if (!Object.keys(this.props.finalBudget).length) {
      return <Redirect to={'/'} />
    }
    const {cities, t} = this.props;
    const {first_name, last_name, phone, whatsapp, email, driver_license} = this.props.finalBudget;
    const {flight_number, travel_company, reservation_code, payment_type, amount, payment_url} = this.props.finalBudget;
    const budget = {...this.props.finalBudget.budget};
    let mailCalling = '';
    let reservationHint  = '';

    let finalBudget = amount.toFixed(2).replace(',','').replace('.',',');

    let gateway = payment_type == 1 ? 'PayPal' : 'EBANX';

    mailCalling =  <p className="email-calling"> {t('budget.review.email-1')} <br/>
    <Trans i18nkey="budget.review.email-2" defaults="budget.review.email-2" values={{gateway: gateway}} />
    <br/>
        {t('budget.review.email-3')}</p>;
    return (
        <Budget>
            <div className="review-and-pay">
            <div className="container clipboard">
            <div className="columns is-mobile is-multiline">
                <div className="column is-12-desktop is-12-mobile">
                <div className="box main-content">
                    <div className="content">
                        <PaymentConfirmation 
                            paymentType={payment_type} 

                        />
                    </div>
                    <hr />
                    <FontAwesomeIcon icon="key" />
                    <h1 className="title">{t('budget.review.title')}</h1>
                    <div className="category-container">
                        <div className="row">
                            <Col md={5}>
                                <div className="image-container">
                                    <img alt='category' src={apiBaseUrl + budget.category.img_url} />
                                </div>
                            </Col>
                            <Col md={7}>
                                <div className="category-details">
                                    <h1 className="title">{budget.category.website_title}</h1>
                                    <h2 className="subtitle">{budget.category.website_subtitle}</h2>
                                    <div className="features-list">
                                        <div className="feature">
                                            <FontAwesomeIcon icon="male"/>
                                            <span>{t('budget.review.seats', {'seats': budget.category.spots})}</span>
                                        </div>
                                        <div className="feature">
                                            <FontAwesomeIcon icon="briefcase"/>
                                            <span>{t('budget.review.baggages', {'baggages': budget.category.baggages})}</span>
                                        </div>
                                        <div className="feature">
                                            <FontAwesomeIcon icon="sitemap"/>
                                            <span>{budget.category.gearbox == 1 ? t('budget.review.automatic') : t('budget.review.manual')}</span>
                                        </div>
                                    </div>
                                    <div className="payment-amount">
                                        {t('budget.review.total', {total: finalBudget})}
                                    </div>
                                    <Trans i18nkey="budget.review.code" defaults="budget.review.code" values={{code: reservation_code}} />
                                </div>
                            </Col>
                        </div>
                    </div>
                    <div className="box receipt">
                    <h2 className="title">{t('budget.review.date_place')}</h2>
                    <Row>
                        <Col md={6}>
                            <h3 className="subtitle">{t('budget.review.delivery')}</h3>
                            <p>{cities[budget.origin_location].name}</p>
                            <p>{t('budget.review.date_time', {
                                date: moment(budget.begin_date).format('DD/MM/YYYY'), 
                                day: moment(budget.begin_date).format('DD'), 
                                month: moment(budget.begin_date).format('MM'), 
                                year: moment(budget.begin_date).format('YYYY'), 
                                time: moment(budget.begin_date).format('h:m')})}</p>
                        </Col>
                        <Col md={6}>
                            <h3 className="subtitle">{t('budget.review.return')}</h3>
                            <p>{cities[budget.destination_location].name}</p>
                            <p>{t('budget.review.date_time', {
                                date: moment(budget.end_date).format('DD/MM/YYYY'),
                                day: moment(budget.end_date).format('DD'), 
                                month: moment(budget.end_date).format('MM'), 
                                year: moment(budget.end_date).format('YYYY'),  
                                time: moment(budget.end_date).format('h:m')})}</p>
                        </Col>
                    </Row>
                    <div className="extras">
                        <h2 className="title">{t('budget.review.extras')}</h2>
                        {Object.keys(budget.extras).map(extra => {
                            let currentExtra = this.props.extras[extra];
                            let amountExtra = 
                                currentExtra.daily_value == 0
                                    ? parseFloat(currentExtra.value)
                                    : parseFloat(currentExtra.value * budget.duration);
                                amountExtra = amountExtra * budget.extras[extra];
                        
                            return (
                                <Row key={1} className="d-flex">
                                    <Col md={4} xs={5} className="price_column"> 
                                        $ <span className="extra_price">{amountExtra.toFixed(2).replace(',','').replace('.',',')}</span>
                                    </Col>
                                    <Col md={3} xs={2} className="line_column"><div className="line" /></Col>
                                    <Col md={4} xs={5}className="extra_tag">{budget.extras[extra]} × {currentExtra.name}</Col>
                                </Row>
                            );
                            })}

                                
                        <Row key={1} className="d-flex align-items-center">
                            <Col md={4} xs={5} className="price_column"> 
                                R$ <span className="extra_price">{parseFloat(cities[budget.origin_location].rent_tax).toFixed(2).replace(',','').replace('.',',')}</span>
                            </Col>
                            <Col md={3} xs={2} className="line_column"><div className="line" /></Col>
                            <Col md={4} xs={5} className="extra_tag">{t('budget.review.rent_tax')}</Col>
                        </Row>
        
                                
                        <Row key={1} className="d-flex align-items-center">
                            <Col md={4} xs={5} className="price_column"> 
                                R$ <span className="extra_price">{(parseFloat(cities[budget.origin_location].surcharge) * budget.duration).toFixed(2).replace(',','').replace('.',',')}</span>
                            </Col>
                            <Col md={3} xs={2} className="line_column"><div className="line" /></Col>
                            <Col md={4} xs={5} className="extra_tag">{t('budget.review.surcharge')}</Col>
                        </Row>
                                
                        <Row key={1} className="d-flex align-items-center">
                            <Col md={4} xs={5} className="price_column"> 
                                <span className="extra_price">{cities[budget.origin_location].sales_tax}%</span>
                            </Col>
                            <Col md={3} xs={2} className="line_column"><div className="line" /></Col>
                            <Col md={4} xs={5} className="extra_tag">{t('budget.review.sales_tax')}</Col>
                        </Row>
    
                        </div>
                        {reservationHint}
                        <hr />
                        <div className="client">
                        <h2 className="title">{t('budget.review.customer')}</h2>
                        <p>{first_name} {last_name}</p>
                        <Row>
                            <Col md={6} className="column is-6">
                                <h3 className="subtitle">{t('budget.review.phone')}:</h3>
                                <p>{phone}</p>
                            </Col>
                            <Col md={6} className="column is-6">
                                <h3 className="subtitle">{t('budget.review.whatsapp')}:</h3>
                                <p>{whatsapp}</p>
                            </Col>
                            <Col md={6} className="column is-12">
                                <h3 className="subtitle">{t('budget.review.cnh')}:</h3>
                                <p>{driver_license}</p>
                            </Col>
                            <Col md={6} className="column is-12">
                                <h3 className="subtitle">{t('budget.review.email')}:</h3>
                                <p>{email}</p>
                            </Col>
                        </Row>
                        <h2 className="title">{t('budget.review.arrival_flight')}</h2>
                        <Row className="">
                            <Col md={6} className="">
                            <h3 className="subtitle">{t('budget.review.flight_company')}</h3>
                            <p>{travel_company !== '' ? travel_company : t('budget.review.undefined')}</p>
                            </Col>
                            <Col md={6} className="">
                            <h3 className="subtitle">{t('budget.review.flight_number')}</h3>
                            <p>{flight_number !== '' ? flight_number : t('budget.review.undefined')}</p>
                            </Col>
                        </Row>
                        </div>
                    </div>
                    {mailCalling}
                    </div>
                    </div>
                </div>
            </div>
            </div>
        </Budget>
    );
    }
};

const mapStateToProps = ({website, budget}) => {
  return {
    referral: website.referral,
    currency_hash: website.currency_hash,
    cities: website.cities,
    extras: website.extras,
    finalBudget: budget.final_budget
  };
};

const mapDispatchToProps = dispatch => {
  return {
      step: (payload) => dispatch({type: 'STEP', payload: payload})
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation()(ReviewAndPay)));
