import React from 'react';
import Base from '../base/base.component';
import './faq.styles.scss';
import CompanyBackground from '../../assets/company.jpg';
import { faq } from '../../utilities/content/company-content';
import FaqOption from '../../components/faq-option/faq-option.component';
import { withTranslation } from 'react-i18next';

class Faq extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            faqSearch: '',
            questions: faq,
            filteredQuestions: faq
        }
    }
    
    filterFaq = (value) => {
        let questions = this.state.questions;

        let filteredQuestions = questions.filter(element => (element.question.includes(value)) || (element.answer.includes(value)));

        this.setState({filteredQuestions: filteredQuestions});
    }

    render() {
        const {t} = this.props;
        return (
            <Base header={{ bgType: 'transparent', layout: 'homepage', links: true }} footer bgVariant="#000">
                <section className="faq">
                    <div className="heading" style={{backgroundImage: `url(${CompanyBackground})` }}>
                        <h1>{t('faq.title')}</h1>
                        <input type="text" className="faq-search" onChange={(event) => this.filterFaq(event.target.value)} />
                        <small>{t('faq.subtitle')}</small>
                    </div>
        
                    <div className="faq-content">
                        <div className="container">
                            <h1>{t('faq')}</h1>
                            {this.state.filteredQuestions.map((element, index) => {
                                return(
                                    <FaqOption question={t(element.question)} answer={t(element.answer)} />
                                )
                            })}
                        </div>
                        </div>
                </section>
            </Base>
        );
    }
}


export default withTranslation()(Faq);