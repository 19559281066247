import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Image from 'react-bootstrap/Image';
import Dropdown from 'react-bootstrap/Dropdown';
import NavLink from '../nav-link/nav-link.component';
import SearchForm from '../search-form/search-form.component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LanguageChange from '../language-change/language-change.component';
import './header.styles.scss';
import { SUPPORTED_CURRENCIES } from '../../utilities/utility';
import { withTranslation } from 'react-i18next';

import Logo from '../../assets/logo.svg';
import SVG from 'react-inlinesvg';
import UserImage from '../../assets/user-img.jpg';
import Flag from 'react-flagkit';
class Header extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        logged: false,
        searchPopped: false
      }
    }

    switchDropdown(eventKey) {
      switch (eventKey) {
        case 'logout':
          this.props.logout();
          break;
        default:
          break;
      }
    }

    handleSubmit = () => {
        const {origin_location, destination_location, begin_date, end_date, begin_time, end_time} = this.props;

        let params = {
            origin_location,
            destination_location,
            begin_date: begin_date.format('YYYY-MM-DD'),
            end_date: end_date.format('YYYY-MM-DD'),
            begin_time: begin_time,
            end_time: end_time

        }
        let queryParams = new URLSearchParams(params).toString();
        this.setState({searchPopped: false});
        this.props.history.replace({
            pathname: '/s',
            search: queryParams
        });
    }

    render() {
        const {t} = this.props; 
        const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
          <a
            href="#"
            ref={ref}
            onClick={e => {
              e.preventDefault();
              onClick(e);
            }}
          >
            {children}
          </a>
        ));
        const logoType = this.props.bgType === 'light' ? '#000' : '#fff';
        return (
          <div id="header">
            <Navbar bg={this.props.bgType} variant={this.props.bgType} expand='lg' className={`header desktop d-none d-sm-none d-md-flex ${this.props.layout}`}>
            {
              this.props.withSearch
              ?
                (
                  <div className="params-wrapper">
                    <button className="btn btn-primary search-params" onClick={() => this.setState({searchPopped: !this.state.searchPopped})}>
                    {t('budget.header', {
                      "date_from": this.props.begin_date.format('DD/MM/YYYY'), 
                      "date_to": this.props.end_date.format('DD/MM/YYYY'), 
                      "day_from": this.props.begin_date.format('DD'),
                      "month_from": this.props.begin_date.format('MM'),
                      "year_from": this.props.begin_date.format('YYYY'),
                      "day_to": this.props.end_date.format('DD'),
                      "month_to": this.props.end_date.format('MM'),
                      "year_to": this.props.end_date.format('YYYY'),
                      "end_time": this.props.end_time, 
                      "begin_time": this.props.begin_time, 
                      "origin_location": this.props.cities[this.props.origin_location] ? this.props.cities[this.props.origin_location].name : '', 
                      "destination_location": this.props.cities[this.props.destination_location] ? this.props.cities[this.props.destination_location].name : ''})}
                    </button>
                  </div>
                )
              : null
            }
              <div className="content-delimiter">
                <Navbar.Brand style={{zIndex: 3}}><NavLink style={{color: logoType}} link="/"> 
                  <SVG 
                    src={Logo} 
                    fill="#fff"
                    preProcessor={code => code.replace(/fill:#[A-Fa-f0-9]+/g, 'fill:rgb(255,255,255);')}
                  /> 
                </NavLink></Navbar.Brand>
                <Navbar.Collapse className="justify-content-end">
                  <Nav>
                    { this.props.links 
                      ? (
                        <React.Fragment>
                        <a className="nav-link mr-3" href="https://linktr.ee/vcusarentacar" target="_blank" rel="noopener noreferrer"><b><FontAwesomeIcon icon={['fab', 'whatsapp']} /> {t('whatsapp_contact')}</b></a>
                        <NavLink link="/faq" >
                          {t('faq')}
                        </NavLink>
                            <div className="navbar-item has-dropdown is-hoverable">
                                <a className="navbar-link is-arrowless" onClick={() => this.props.toggleModal(
                                { action: `open`, 
                                children: 
                                  <LanguageChange
                                  /> })} >
                                  <Flag country={SUPPORTED_CURRENCIES[this.props.seen_language]['flag']} /> &nbsp; <FontAwesomeIcon icon="angle-down" />
                                </a>
                          </div>
                        </React.Fragment>
                      )
                      : null
                    }

                  </Nav>
                </Navbar.Collapse>
              </div>
            </Navbar>
            {this.props.withSearch
              ?
              <Navbar bg={this.props.bgType} variant={this.props.bgType} expand='lg' className={`justify-content-center search-header ${this.state.searchPopped ? 'open' : ''}`}>
                <div className="content-delimiter">
                  <Navbar className="justify-content-center">
                  {
                    this.props.withSearch
                    ? 
                      (<Nav className="">
                        <SearchForm inline handleSubmit={this.handleSubmit} />
                        </Nav>
                      )
                    : null
                  }
                  </Navbar>
                  <button
                    onClick={() => this.setState({searchPopped: !this.state.searchPopped})}
                    className="btn btn-primary close-search"
                    ><FontAwesomeIcon icon="times" />
                  </button>
                </div>
              </Navbar>
              : null
            }
            <Navbar bg="secondary" variant="secondary" className={`header mobile d-flex d-sm-flex d-md-none ${this.props.layout}`}>
                <Navbar.Collapse className="justify-content-start">
                  <Nav>
                  <Nav.Link href="/" className='font-weight-bold'>Inicio</Nav.Link>
                  {
                    this.props.withSearch
                    ?
                      (
                        <button
                          type="submit"
                          className="btn btn-primary inline"
                          onClick={() => this.setState({searchPopped: !this.state.searchPopped})}
                          ><FontAwesomeIcon icon="search" />
                        </button>
                      )
                    : null
                  }
                    { this.props.links 
                      ? (
                        <React.Fragment>
                            <div className="navbar-item has-dropdown is-hoverable flags">
                                <a className="navbar-link is-arrowless" onClick={() => this.props.toggleModal(
                                { action: `open`, 
                                children: 
                                  <LanguageChange
                                  /> })} >
                                  <Flag country={SUPPORTED_CURRENCIES[this.props.seen_language]['flag']} /> &nbsp; <FontAwesomeIcon icon="angle-down" />
                                </a>
                          </div>
                        </React.Fragment>
                      )
                      : null
                    }
                  </Nav>
                </Navbar.Collapse>
            </Navbar>
          </div>
        );
    }
}

const mapStateToProps = ({budget, website}) => {
  return {
    isLogged: website.isLogged,
    seen_language: website.seen_language,
    origin_location: budget.origin_location,
    destination_location: budget.destination_location,
    begin_date: budget.begin_date,
    end_date: budget.end_date,
    begin_time: budget.begin_time,
    end_time: budget.end_time,
    cities: website.cities
  }
}

const mapDispatchToProps = dispatch => {
  return {
    logout: (action) => dispatch({type: "LOGOUT"}),
    toggleModal: (payload) => dispatch({type: 'TOGGLE_MODAL', payload: {action: payload.action, children: payload.children}})
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withRouter(Header)));
