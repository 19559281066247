import React from 'react';
import { Api } from '../../services/api';
import { withTranslation } from 'react-i18next';
class EbanxPayment extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            payment_url: ''
        }
    }

    componentDidMount() {
        const {email, reservationCode, gateway, paymentType} = this.props;
        Api.post(`resolvePayment`, {
            email,
            reservation_code: reservationCode,
            gateway,
            payment_type: paymentType
        }).then(response => {
            if (response.data.hasOwnProperty("payment")) {
                this.setState({payment_url: response.data.payment.redirect_url, loading: false});
            }
        });
    }
    
    render() {
        const {t} = this.props;
        let payment = t('budget.payment.loading-payment');
        if (this.state.loading === false && this.state.payment_url !== '') {
            let {payment_url} = this.state;
            payment = <a className="payment-link" href={payment_url} target="_blank">{t('budget.payment.ebanx-make-payment')}</a>;
        }
        return (
          <div>
            <p>
                {t('budget.payment.ebanx')}
            </p>
            {payment}
          </div>
        );
    }
}

export default withTranslation()(EbanxPayment);