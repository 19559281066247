import React, { Component } from 'react';
import {Api} from '../../services/api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DISCOUNT_TYPES } from '../../utilities/utility';
import {connect} from 'react-redux';
import './coupon-card.styles.scss';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
class CouponCard extends Component {
	constructor(props) {
        super(props);
        this.state = {
            applied: false,
            error: false,
			appliedCoupon: null
        }
	}

	componentWillMount() {
		if (this.props.appliedCoupon != null) {
			this.setState({
				applied: true,
				error: false,
				appliedCoupon: this.props.appliedCoupon,
				coupon: this.props.appliedCoupon.code
			});
		}
	}

  handleChange = (element) => {
    let stateToUpdate = {...this.state};
    stateToUpdate[element.origin] = element.value;
    this.setState(stateToUpdate);
  }

  closeComponent = () => {
  	this.setState({
  		applied: false,
  		error: false,
  		appliedCoupon: null
  	});
 	this.props.removeCoupon();
  }

  getCoupon = () => {
	let currentCoupon = this.state.coupon;
	let stateToUpdate = null;  
    Api.get(`coupon`, {
      params: {
        code: currentCoupon,
        valid_from: moment(this.props.begin_date).format("YYYY-MM-DD"),
        valid_to: moment(this.props.end_date).format("YYYY-MM-DD"),
      },
    })
      .then((response) => {
        this.props.resolveCoupon({
          applied: true,
          appliedCoupon: response.data,
        });
        this.setState({
          applied: true,
          appliedCoupon: response.data,
          error: false,
        });
      })
      .catch((error) => {
        this.setState({
          applied: false,
          error: true,
        });
      });
  }

	render(props) {
		const {t} = this.props;
		let couponDiv = '';
		if (!this.state.applied) {
			couponDiv = <div className="row">
    			<div className="col-sm-12 mt-3 mb-3">
    				<button className="btn-primary apply-coupon" onClick={() => this.getCoupon()}>
           	        <FontAwesomeIcon icon="check" />
	    				{t('budget.budget-configuration.coupon.apply')}
	    			</button>
    			</div>
	    		<div className="col-sm-8">
           	        <a className="return" onClick={() => this.props.toggleModal({action: 'close'})}>
           	        <FontAwesomeIcon icon="chevron-left" />
           	        {t('budget.budget-configuration.coupon.return')}
                     </a>
	    		</div>
	    		<div className="col-sm-4">
           	        <a onClick={() => this.closeComponent()}>
           	        	{t('budget.budget-configuration.coupon.remove')}
                     </a>
	    		</div>
			</div>
		} else {
			let couponTypeDiv = '';
			let appliedCoupon = this.state.appliedCoupon;
			if (appliedCoupon.type === '1') {
 				couponTypeDiv =
 				<div>
					<h4 className="title text-center"> 
						{t('budget.budget-configuration.coupon.call', {'discount_value': appliedCoupon.specification.discount_value, 'discount_type': DISCOUNT_TYPES[appliedCoupon.specification.discount_type]})}
					</h4>
	 			</div>
			} else if (appliedCoupon.type === '2') {
				let extras = appliedCoupon.specification.extras;
				couponTypeDiv = 
					<div>				
						<h4 className="title text-center"> CUPOM: {this.state.appliedCoupon.name} </h4>
						<p> Descontos: </p>
						{Object.keys(appliedCoupon.specification.extras).map((key) => {
							let currentExtra = appliedCoupon.specification.extras[key];
							return (
								<div key={currentExtra.id}>
		                          <div className="extra_option" key={currentExtra.id}> 
		                            <div className="row align-items-center">
		                              <div className="col-xs-2">
		                                <div className="image-box">
		                                  <img src={this.props.baseUrl + currentExtra.icon_url} alt="" />
		                                </div>
		                              </div>
		                              <div className="col-xs-4">
		                                <h1 className="title">{currentExtra.name}</h1> 
		                              </div>
		                              <div className="col-xs-4">
		                                <h1 className="value discounted">$ {currentExtra.value}{currentExtra.daily_value == 1 ? '/' + t('budget.search.daily') : '' }</h1> 
		                                <h1 className="value">$ 0,00</h1> 
		                              </div>
		                              <div className="col-xs-2 price_tag">
		                              	<FontAwesomeIcon icon="check" />
		                              </div>
		                            </div>
		                          </div>
		                        </div>
						);
						})}
					</div>
			}
			couponDiv = 
			<div>
				<div className="card mb-3 mt-3"> 
					<div className="card-body">
						<h1 className="title applied text-center">
							<FontAwesomeIcon icon="check" />
							{t('budget.budget-configuration.coupon.applied-call')}
						</h1>
						{couponTypeDiv}
						<p className="description">{appliedCoupon.description}</p>
					</div>
				</div>
				<div className="row">
	    			<div className="col-sm-8">
	    				<button className="button apply-coupon" onClick={() => this.props.toggleModal({action: 'close'})}>
	           	        <FontAwesomeIcon icon="chevron-left" />
		    				{t('budget.budget-configuration.coupon.return')}
		    			</button>
	    			</div>
	    			<div className="col-sm-4">
	           	        <a onClick={() => this.closeComponent()}>
	           	        	{t('budget.budget-configuration.coupon.remove')}
	                     </a>
	    			</div>
				</div>
		</div>
		}
		let error = '';
		if (this.state.error) {
			error = <div className="col-sm-12">
			<article class="alert alert-danger">
			    {t('budget.budget-configuration.coupon.404')}		  
			</article>
          </div>
		}
		let buttonClass = this.state.coupon ? 'applied' : 'apply-coupon';
		return (
			<div className="CouponCard">
			  <div className="card-content">
			    <div className="content">
		    		<h1 className="title has-text-centered">
		    			{t('budget.budget-configuration.apply-coupon')}
		    		</h1>
		    		<div className="row justify-content-center align-items-center">
		    		{error}
		    			<div className="col-md-12">
							<div className="input-group">
								<input 
									className="form-control" 
									value={this.state.coupon}
									maxLength='30'
									disabled={this.state.applied}
									onChange={(element) => this.handleChange({origin: 'coupon', value: element.target.value})}
									type="text"
								/>
							</div>
		    			</div>
		    		</div>
		    		{couponDiv}
			    </div>
			  </div>
			</div>
		);
	}
};

const mapStateToProps = ({website, budget}) => ({
	begin_date: budget.begin_date,
	end_date: budget.end_date
});

const mapDispatchToProps = (dispatch) => ({
    setCoupon: (payload) => dispatch({type: 'SET_COUPON', payload: payload}),
    toggleModal: (payload) => dispatch({type: 'TOGGLE_MODAL', payload: {action: payload.action, children: payload.children}})
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(CouponCard));
