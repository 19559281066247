import React from 'react';

import FormInput from '../form-input/form-input.component';
import CustomButton from '../custom-button/custom-button.component';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import './sign-in.styles.scss';

class SignIn extends React.Component {
  constructor(props) {
    super(props);
    if (this.props.isLogged) {
      this.props.history.push('/');
    }
    this.state = {
      email: '',
      password: ''
    };
  }

  handleSubmit = event => {
    event.preventDefault();
    this.props.login();
    this.props.history.push('/');
    this.setState({ email: '', password: '' });
  };

  handleChange = event => {
    const { value, name } = event.target;

    this.setState({ [name]: value });
  };

  render() {
    return (
      <div className='sign-in'>
        <h2>Já possuo uma conta</h2>
        <span>Entre com seu e-mail e senha</span>

        <form onSubmit={this.handleSubmit}>
          <FormInput
            name='email'
            type='email'
            handleChange={this.handleChange}
            value={this.state.email}
            label='email'
          />
          <FormInput
            name='password'
            type='password'
            value={this.state.password}
            handleChange={this.handleChange}
            label='senha'
          />
          <div className="control-buttons">
            <CustomButton type='submit'> Entrar </CustomButton>
            <a onClick={() => this.props.switchComponent('register')}> Não possui uma conta? Registrar </a>
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return { 
    isLogged: state.website.isLogged
  };
}

const mapDispatchToProps = dispatch => {
  return {
    login: (payload) => dispatch({type: "LOGIN"})
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SignIn));
