import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ListIcon = (props) => {
	return (
      <li className={props.className}><span className="fa-li"><FontAwesomeIcon icon={props.icon} /></span> <span className="li-text">{props.description}</span></li>		
	);
}

export default ListIcon;
