import React from 'react';
import './homepage.styles.scss';
import Header from '../../components/header/header.component';
import Footer from '../../components/footer/footer.component';
import Landing from '../../sections/landing/landing.section';
import Cities from '../../sections/cities/cities.section';
import Vehicles from '../../sections/vehicles/vehicles.section';
import Contacts from '../../sections/contacts/contacts.section';
import Instagram from '../../sections/instagram/instagram.section';
import Company from '../../sections/company/company.section';
import Location from '../../sections/location/location.section';
import Services from '../../sections/services/services.section';
const Homepage = () => (
    <div className="homepage">
      <Header bgType="transparent" links layout='homepage' />
      <Landing />
      <Cities />
      <Vehicles />
      <Services />
      <Company />
      <Location />
      <Contacts />
      <Footer />
    </div>
);

export default Homepage;