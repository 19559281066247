import React from 'react';
import './service-card.styles.scss';
import SVG from 'react-inlinesvg';
import {useTranslation} from 'react-i18next';
const ServiceCard = ({title, description, iconRef}) => {
    const { t, i18n } = useTranslation();
    return(
        <div className="service-card">
            <div className="icon-container">
                <SVG src={iconRef} />
            </div>
            <h5 className="text-center mt-4 mb-3"><b>{t(title)}</b></h5>
            <p className="service-description text-center">{t(description)}</p>
        </div>
    );
}

export default ServiceCard;