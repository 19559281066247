import React from 'react';
import './extras.styles.scss';
import { apiBaseUrl } from '../../utilities/config';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Redirect } from 'react-router-dom';
import { NavHashLink as NavLink } from 'react-router-hash-link';

import { withTranslation } from 'react-i18next';

import CouponCard from '../../components/coupon/coupon-card.component';

class Extras extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedExtras: null,
            availableExtras: [],
            requiredExtras: [],
            appliedCoupon: null
        }
    }

    componentDidMount() {
        this.props.step({
            step: 1
        });
    }

    componentDidUpdate(props, state) {
        if (state.selectedExtras === null) {
            this.setState({...this.state, selectedExtras: props.selectedExtras});
        }
    }

    addExtra = (extra) => {
    let selectedExtras = this.props.selectedExtras;
    let quant          = !(extra in selectedExtras) ? 1 : selectedExtras[extra] + 1;
    this.setExtra(extra, quant);
    this.props.setExtra(extra, quant);
    this.props.calculateTotal();
    }

    subtractExtra = (extra) => {
    let selectedExtras = this.props.selectedExtras;
    if (!(extra in selectedExtras)) {
        return false;
    }
    let quant  = selectedExtras[extra] - 1;
    this.setExtra(extra, quant);
    this.props.setExtra(extra, quant);
    this.props.calculateTotal();
    }

    setExtra = (id, quant) => {
        let currentState = {
            ...this.state
        };

        if (quant === 0) {
            delete currentState.selectedExtras[id];
        } else {
            currentState.selectedExtras[id] = quant;
        }

        this.setState(currentState);
    };
    
    hasExtraCouponApplied = () => {
    if (this.state.appliedCoupon !== null && this.state.appliedCoupon.type == 2) {
        return true;
    }
    return false;
    }

        
    render() {
        const {t} = this.props;
        let availableExtras = Object.values(this.props.extras).filter(extra => {
            return extra.required == 0;
        });
        let requiredExtras= Object.values(this.props.extras).filter(extra => {
            return extra.required == 1;
        });
        return(
            <div>
                <h1 className="title">{t('budget.extras.protections.title')}</h1>
                <h2 className="subtitle">{t('budget.extras.protections.description')}</h2>
                <ul>
                    {(requiredExtras).map(ele => {
                    return (
                        <li key={ele.id} className="protectionsWrapper">
                        <div className="list-icon">
                            <span><FontAwesomeIcon icon="check" /></span>
                        </div>
                        <div className="protections">
                            {ele.name}
                        </div>
                        </li>
                        )
                    }
                    )}
                </ul>
                <h1 className="title">{t('budget.extras.extras.title')}</h1>
                <h2 className="subtitle">{t('budget.extras.extras.description')}</h2>
                <Row className="align-items-center justify-items-center flex-wrap">
                {(availableExtras).map(ele => {
                let amount = ele.daily_value == 1 ? (ele.value) * parseInt(this.props.duration) : parseFloat(ele.value);
                amount = (this.props.selectedExtras[ele.id])
                ? amount * this.props.selectedExtras[ele.id]
                : amount;

                let couponDiv = 
                    <Col lg={6} className="align-items-stretch" key={ele.id}>
                    <div className="extra_option align-self-stretch" key={ele.id}>
                        <Row className="">
                            <Col lg={3}className="d-none d-sm-flex">
                                <div className="image-box">
                                <img src={apiBaseUrl + ele.icon_url} alt="" />
                                </div>
                            </Col>
                            <Col md={9} sm={9} xs={12}>
                                <Row className="extra_details">
                                    <div className="heading">
                                        <h1 className="title">{ele.name}</h1> 
                                        <p className="details">{t('budget.extras.extras.more-details')}</p>
                                    </div>
                                    <Col md={12} sm={12} xs={12} className="price_tag d-flex text-align-left">$ {amount.toFixed(2).replace(',','').replace('.',',')}</Col>
                                    <Col lg={12} className="quantity_tag pl-0 d-flex text-align-left">
                                        <div className="quantity_wrapper ">
                                            <span className="extra_quantity">                              
                                            {(this.props.selectedExtras[ele.id])
                                                ? this.props.selectedExtras[ele.id]
                                                : 0
                                            }</span>
                                            <div className="quantity_controllers">
                                                <button className={(this.props.extras[ele.id])
                                                ? ''
                                                : 'disabled'} onClick={() => this.subtractExtra(ele.id)}><FontAwesomeIcon icon="minus" /></button>
                                                <button onClick={() => this.addExtra(ele.id)}><FontAwesomeIcon icon="plus" /></button>
                                                <div className="divider"></div>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>
                    </Col>;
                if (this.hasExtraCouponApplied()) {
                    let appliedCoupon = this.state.appliedCoupon;
                    (appliedCoupon.specification.extras).filter((extra) => {
                    if (extra.id === ele.id) {
                        couponDiv = '';
                    }
                    });
                }
                return (
                    couponDiv
                );
                }
                )}
                </Row>
            </div>
        );
    }
}

const mapStateToProps = ({website, budget}) => ({
    car_category: budget.car_category,
    origin_location: budget.origin_location,
    destination_location: budget.destination_location,
    begin_date: budget.begin_date,
    end_date: budget.end_date,
    duration: budget.duration,
    cities: website.cities,
    exchange_currency: website.exchange_currency,
    currency_hash: website.currency_hash,
    selectedExtras: budget.selectedExtras,
    extras: website.extras
});

const mapDispatchToProps = (dispatch) => ({
    setHash: (payload) => dispatch({type: 'CURRENCY_HASH', payload: payload}),
    step: (payload) => dispatch({type: 'STEP', payload: payload})
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Extras));