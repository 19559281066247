import React from 'react';
import './faq-option.styles.scss';

class FaqOption extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isOpen: false
        }
    }
    toggleOption = () => {
        let isOpen = !(this.state.isOpen);
        this.setState({
            isOpen: isOpen
        });
    }
    render() {
        const {question, answer} = this.props;

        return(
            <div className="faq-option">
                <div className="faq-question" onClick={() => this.toggleOption()}>
                    <div className="faq-letter">
                        Q.
                    </div>
                    <div>
                        {question}
                    </div>
                </div>
                <div className={`faq-answer ${this.state.isOpen ? 'open' : ''}`}>
                    <div className="faq-letter">
                        A.
                    </div>
                    <div>
                        {
                            answer.split('\n').map(function (item, key) {
                                return (
                                    <span key={key}>
                                        {item}
                                        <br />
                                        <br />
                                    </span>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default FaqOption;