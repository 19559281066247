import React from 'react';
import './loading.styles.scss';

const Loading = ({handleClose, show, children}) => {
  const showHideClass = show ? "loading active" : "loading";
  return (
    <div className={showHideClass}>
      <div className="loading-background"></div>
      <div className="loading-content">
        <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
      </div>
    </div>
  );
}

export default Loading;
