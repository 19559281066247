import React from 'react';
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from 'pure-react-carousel';

import SquareBanner from '../square-banner/square-banner.component';
import InstagramCard from '../instagram-card/instagram-card.component';
import IteratableInformation from '../iteratable-information/iteratable-information.component';
import 'pure-react-carousel/dist/react-carousel.es.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons'
import { faAngleRight } from '@fortawesome/free-solid-svg-icons'

import './slider-container.styles.scss';
import { useWindowSize } from '../../hooks/useResize';

import {connect} from 'react-redux';


const SliderContainer = ({iteratableObject, length, height, width, visible, classType, objectType, extraProps, toggleModal}) => {
    let windowsSize = useWindowSize();
    let visibleSlides = visible;
    if (windowsSize[0] < 767.98) {
      visibleSlides = 1;
    }
      
    return (
        <CarouselProvider
        naturalSlideWidth={width}
        naturalSlideHeight={height}
        totalSlides={length}
        visibleSlides={visibleSlides}
        infinite={true}
        step={1}
        >
            <Slider classNameTray="slider">
                <div className="slider-container">
                {iteratableObject.map((object, index) => {
                  let objectTypeCheck = (objectType === 'instagram');
                  return (
                    <Slide 
                      className="slide" 
                      index={index} 
                      onClick={!objectTypeCheck ? () => toggleModal({'action': 'open', children: <IteratableInformation {...object} objectType={objectType} />}) : null } 
                      >

                      {objectTypeCheck
                        ? <InstagramCard {...object} {...extraProps} />
                        : <SquareBanner {...object} classType={classType} />
                      }
                    </Slide>
                  );
                })}
                </div>
            </Slider>
            <ButtonBack className="controller back font-weight-bold" children={<FontAwesomeIcon icon={faAngleLeft}/>} />
            <ButtonNext className="controller next font-weight-bold" children={<FontAwesomeIcon icon={faAngleRight}/>} />
        </CarouselProvider>
      );
            
}

const mapStateToProps = state => {
  return {
  }
}

const mapDispatchToProps = dispatch => {
  return {
      toggleModal: (action) => dispatch({type: "TOGGLE_MODAL", payload: {action: action.action, children: action.children}})
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SliderContainer)