export const companyInfo = [
    {
        'description': 'company-info-1',
        'image':'https://via.placeholder.com/640x480'
    },
    {
        'description': 'location.description',
        'map': "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1067.7655182866872!2d-80.33293427357972!3d25.819379222904757!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88d9bbda0155415d%3A0xfe51c26989d7958c!2s8350%20NW%2052nd%20Terrace%20%23301%2C%20Doral%2C%20FL%2033166%2C%20USA!5e0!3m2!1sen!2sbr!4v1588695197383!5m2!1sen!2sbr"
    },
    {
        'description': 'company-info-2',
        'image': 'https://via.placeholder.com/640x480'
    }
];

export const featured_services = [
    {
        'title': 'payment',
        'description': 'payment.description',
        'icon': 'pay'
    },
    {
        'title': 'relationship',
        'description': 'relationship.description',
        'icon': 'talk'
    },
    {
        'title': 'delivery',
        'description': 'delivery.description',
        'icon': 'key'
    }
];

export const services = [
    'company-content-1',
    'company-content-2',
    'company-content-4',
    'company-content-5',
    'company-content-6'
];

export const faq = [
    {
        'question': 'faq.question_1',
        'answer': 'faq.answer_1'
    },
    {
        'question': 'faq.question_2',
        'answer': 'faq.answer_2'
    },
    {
        'question': 'faq.question_3',
        'answer': 'faq.answer_3'
    },
    {
        'question': 'faq.question_4',
        'answer': 'faq.answer_4'
    },
    {
        'question': 'faq.question_5',
        'answer': 'faq.answer_5'
    },
    {
        'question': 'faq.question_6',
        'answer': 'faq.answer_6'
    },
    {
        'question': 'faq.question_7',
        'answer': 'faq.answer_7'
    },
    {
        'question': 'faq.question_8',
        'answer': 'faq.answer_8'
    },
    {
        'question': 'faq.question_9',
        'answer': 'faq.answer_9'
    },
    {
        'question': 'faq.question_10',
        'answer': 'faq.answer_10'
    },
    {
        'question': 'faq.question_11',
        'answer': 'faq.answer_11'
    },
    {
        'question': 'faq.question_12',
        'answer': 'faq.answer_12'
    },
    {
        'question': 'faq.question_13',
        'answer': 'faq.answer_13'
    },
    {
        'question': 'faq.question_14',
        'answer': 'faq.answer_14'
    },
    {
        'question': 'faq.question_15',
        'answer': 'faq.answer_15'
    },
];