import React from 'react';
import './company.styles.scss';
import Car from '../../assets/company_call.png'

import { withTranslation, Trans } from 'react-i18next';

const Company = ({t}) => {
  return (
    <section className="company-section" id="company">
        <div className="container">
        
            <div className="row align-items-center">
                <div className="col-lg-12 text-center">                
                <h2 className="text-center">{t('company.title')}</h2>
                </div>
                <div className="col-lg-6 col-md-12">
                <hr />

                <p>{<Trans i18nkey="company-info-1" defaults="company-info-1" />}</p>
                <p>{t('company-info-2')}</p>
                </div>
                <div className="col-lg-6 col-md-12">
                  <div className="image-wrapper">
                    <figure className="image is-5by4">
                        <img src={Car} alt="imagem empresa" />
                    </figure>
                  </div>
                </div>
            </div>
        </div>
    </section>
  );
};

export default withTranslation()(Company);
