import React from 'react';
import { connect } from 'react-redux';
import Budget  from '../budget/budget.component';
import './budget-configuration.styles.scss';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { NavHashLink as NavLink } from 'react-router-hash-link';
import { Api } from '../../services/api';
import { apiBaseUrl } from '../../utilities/config';
import Extras from '../../pages/extras/extras.component';
import PersonalInformation from '../../pages/personal-information/personal-information.component';
import {Switch, Route, Redirect, withRouter} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CouponCard from '../coupon/coupon-card.component';
import {withTranslation} from 'react-i18next';

class BudgetConfiguration extends React.Component {  

    constructor(props) {
        super(props);

        this.state = {
            extras : [],
            amount: 0,
            originalAmount: 0,
            taxes: {},
            selectedExtras: {},
            appliedCoupon: null,
            finalBudget: {}
        };
    }

    async componentDidMount() {
        try {
            let newState = {...this.state};
            const extras = await Api.get(`extras`, {
                params: {
                    exchange_currency: this.props.exchange_currency,
                    currency_hash: this.props.currency_hash
                }
            });
            let extrasMap = {};
            Object.values(extras.data.extras).map(ele => {
                return (extrasMap[ele.id] = ele);
            });
            Object.values(extrasMap)
            .filter(extra => {
                return extra.required == 1;
              })
            .map(ele => {
                newState["selectedExtras"][ele.id] = true;
            });
            
            this.props.setExtras(extrasMap);
            this.props.setSelectedExtras(newState["selectedExtras"]);
            let taxes = {
                'surcharge': this.props.cities[this.props.origin_location].surcharge ? this.props.cities[this.props.origin_location].surcharge : 0,
                'rent_tax': this.props.cities[this.props.origin_location].rent_tax ? this.props.cities[this.props.origin_location].rent_tax : 0,
                'sales_tax': this.props.cities[this.props.origin_location].sales_tax ? this.props.cities[this.props.origin_location].sales_tax : 0
            };
            newState = {
                ...newState,
                taxes: taxes
            }
            this.setState(newState);
            this.calculateTotal();
            if (this.props.currency_hash !== extras.data.currency_hash) {
                this.props.setHash({'currency_hash': extras.data.currency_hash});
            }
        } catch (err) {
            console.log(err);
        }
    }
    
    calculateTotal = (state = this.state) => {
        const {taxes}  = state;
        const {duration, car_category, extras, selectedExtras} = this.props;

        let values = {
            baseValue: parseFloat(car_category.diaria * duration)
        }
        Object.keys(selectedExtras).map(extra => {
            let currentExtra = extras[extra];
            let amountExtra = 
            currentExtra.daily_value == 0
                ? parseFloat(currentExtra.value)
                : currentExtra.value * parseInt(duration);
            amountExtra = amountExtra * selectedExtras[extra];
            return values[currentExtra.id] = amountExtra;
        });
        let total = Object.keys(values).reduce((prevVal, value) => {
            return prevVal + values[value]
        }, 0);
        total = total + (parseFloat(taxes.surcharge) * duration) + parseFloat(taxes.rent_tax);
        total = total + ((total * parseFloat(taxes.sales_tax)) / 100);
        let finalAmount = total;
        if (state.appliedCoupon !== null && state.appliedCoupon.type == 1) {
        let coupon = state.appliedCoupon;
        if (coupon.specification.discount_type == 1) {
            finalAmount = finalAmount - coupon.specification.discount_value;
        } else if (coupon.specification.discount_type == 2) {
            finalAmount = finalAmount - ((finalAmount * coupon.specification.discount_value)/100);
        }
        }
        this.setState({
            originalAmount: total,
            amount: finalAmount
        });
    }


    resolveCoupon = (coupon) => {
        let newState = {
          ...this.state,
          ...coupon
        }
        if (coupon.appliedCoupon.type == 2) {
          let newExtras = this.props.currentBudget.extras;
          (coupon.appliedCoupon.specification.extras).map((extra) => {
            delete newExtras[extra.id];
          });
          this.props.overrideExtra(newExtras);
        }
        this.setState(newState);
        this.props.setCoupon(coupon.appliedCoupon);
        this.calculateTotal();
      }

    removeCoupon = () => {
    let newState = {
        ...this.state, 
        error: false, 
        appliedCoupon: null, 
        applied: null
    };
    this.props.removeCoupon();
    this.setState(newState);
    this.calculateTotal(newState);
    }


    setExtra = (id, quant) => {
        this.props.changeExtra({id, quant});
    }
    
    hasAmountCouponApplied = () => {
        if (this.state.appliedCoupon !== null && this.state.appliedCoupon.type == 1) {
        return true;
        }
        return false;
    }

  postBudget = async (clientInformation) => {
    const {
        car_category,
        origin_location,
        destination_location,
        duration,
        begin_date,
        end_date,
        selectedExtras,
        exchange_currency,
        currency_hash,
        coupon,
        begin_time,
        end_time
    } = this.props;
      
    let budget = {
        budget: {
            category: car_category,
            origin_location,
            destination_location,
            duration,
            begin_date: begin_date.format('YYYY-MM-DD') + ' ' + begin_time,
            end_date: end_date.format('YYYY-MM-DD')+ ' ' + end_time,
            extras: selectedExtras,
            exchange_currency,
            currency_hash,
            coupon
          },
        ...clientInformation
    };

    try {
        let response = await Api.post('budget', budget);
        if (response.status === 200) {
            budget['reservation_code'] = response.data.reservation_code;
            budget['gateway'] = response.data.gateway;
            budget['amount'] = this.state.amount;
            if (response.data.hasOwnProperty("payment")) {
              budget['payment_url'] = response.data.payment.redirect_url;
            }
            if (this.props.referral !== null) {
              Api.post('referral', {
                  'referral': this.props.referral,
                  'type': 3,
                  'budget_id': response.data.budget_id
              });
            }
            await this.props.setFinalBudget(budget);
            this.props.history.push('/order/review#header');
        }
    } catch(error) {
        console.log(error);
    };

  }

    render() {
        const {t} = this.props;
        let discountedAmount = '';
        if (this.hasAmountCouponApplied()) {
          discountedAmount = <h2 className="value discounted"> R$ {this.state.originalAmount.toFixed(2).replace(',', '').replace('.', ',')} </h2>;
        }
        if (this.props.car_category === null) {
            return <Redirect to={'/s'} />
        }
        let coupon = '';
        if (!this.state.appliedCoupon) {
        coupon = 
        <p className="">
            <button className="btn btn-outline-primary coupon-call" onClick={() => this.props.toggleModal({'action': 'open',
            children: <CouponCard 
            description="" 
            baseUrl={apiBaseUrl} 
            resolveCoupon={this.resolveCoupon}
            removeCoupon={this.removeCoupon}
            error={this.state.error}
            appliedCoupon={this.state.appliedCoupon}
            applied={this.state.applied}
            currentBudget={this.props.currentBudget}
            />
        })}>
            <FontAwesomeIcon icon="ticket-alt" /> &nbsp;
            {t('budget.budget-configuration.apply-coupon')}
            </button>
        </p> 
        } else {
        coupon = 
        <div className="buttonWrapper">
            <button className="btn applied-coupon" onClick={() => this.props.toggleModal({
                action: 'open',
                children:
                <CouponCard 
                description="" 
                baseUrl={apiBaseUrl} 
                resolveCoupon={this.resolveCoupon}
                removeCoupon={this.removeCoupon}
                error={this.state.error}
                appliedCoupon={this.state.appliedCoupon}
                applied={this.state.applied}
                />
            }
            )}>
            <div className="row justify-content-center">
                <div className="col-xs-2 d-flex align-items-center">
                <FontAwesomeIcon icon="ticket-alt" /> &nbsp;
                </div>
                <div className="col-xs-10">
                {t('budget.budget-configuration.applied-coupon')} {this.state.appliedCoupon.name}
                </div>
            </div>
            </button>
        </div> 
        }
        const { id, website_title, website_subtitle, website_description, diaria} = this.props.car_category;
        const { origin_location, destination_location, begin_date, end_date, cities, duration, step} = this.props
        let proceedButton = '';
        switch (step) {
            case 1:
                proceedButton = <NavLink to={"/budget/customer#header"} scroll={el => el.scrollIntoView({ behavior: 'smooth', block: 'start' })} className="btn btn-outline-primary btn-block">
                {t('budget.budget-configuration.proceed')}
                </NavLink>;
                break;
            default:
                break;
        }
        return(
            <Budget>
                <section className="extras">
                    <div className="category-banner">
                        <div className="img-container">
                            <img alt="category" src={apiBaseUrl + this.props.car_category.img_url} />
                            <div className="category-info">
                            <h3>
                                {website_title} &nbsp;
                                <small class="text-muted">{website_subtitle}</small><br />
                                <h2 className="subtitle">
                                {t('budget.budget-configuration.description', {
                                    'begin_date': begin_date.format('DD/MM/YYYY'), 
                                    'end_date': end_date.format('DD/MM/YYYY'), 
                                    'day_begin': begin_date.format('DD'), 
                                    'month_begin': begin_date.format('MM'), 
                                    'year_begin': begin_date.format('YYYY'), 
                                    'day_end': end_date.format('DD'), 
                                    'month_end': end_date.format('MM'), 
                                    'year_end': end_date.format('YYYY'), 
                                    'origin_location': cities[origin_location].name, 
                                    'destination_location': cities[destination_location].name})}
                                <br/>
                                {t('budget.budget-configuration.duration-description', {duration})}
                                </h2>
                                <br />
                                <h3>
                                {t('budget.budget-configuration.base-value-description', {base_value: (duration * diaria).toFixed(2).replace(',', '').replace('.', ',')})}
                                </h3>
                            </h3>
                            </div>
                        </div>
                    </div>
                    <div className="protections-extras">
                        <Container fluid>
                            <Row className="section-wrapper">
                                <Col md={8} xs={12} className="protections-wrapper">
                                    <Switch>
                                        <Route
                                            exact
                                            path="/budget"
                                            render={() => <Redirect to="/" />}
                                        />
                                        <Route 
                                            path="/budget/extras" 
                                            render={(props) => 
                                                <Extras 
                                                    setExtra={this.setExtra}
                                                    calculateTotal={this.calculateTotal}
                                                />}
                                        />
                                        />
                                        <Route 
                                            path="/budget/customer" 
                                            render={(props) => 
                                                <PersonalInformation
                                                    postBudget={this.postBudget}
                                                />}
                                        />
                                    </Switch>
                                </Col>
                                <Col md={4}>    
                                    <div className="box main-content" style={{position: 'sticky', top: '5rem'}}>
                                    <h1 class="title"> {t('budget.budget-configuration.payment-details')} </h1>
                                        <div className="extras">
                                        {Object.keys(this.props.selectedExtras).map(extra => {
                                            let currentExtra = this.props.extras[extra];
                                            let amountExtra = 
                                                currentExtra.daily_value == 0
                                                    ? parseFloat(currentExtra.value)
                                                    : parseFloat(currentExtra.value * 1);
                                            amountExtra = amountExtra * this.state.selectedExtras[extra];
                                            return (
                                                <Row className="columns is-mobile is-vcentered">
                                                    <Col md={3} className="price_column"> 
                                                        $ <span className="extra_price">{amountExtra.toFixed(2).replace(',','').replace('.',',')}</span>
                                                    </Col>
                                                    <Col md={9} className="extra_tag">{currentExtra.name}</Col>
                                                </Row>
                                            );
                                        })}
                                        <Row className="columns is-mobile is-vcentered">
                                            <Col md={3} className="price_column"> 
                                                $ <span className="extra_price">{parseFloat(this.state.taxes.rent_tax).toFixed(2).replace(',','').replace('.',',')}</span>
                                            </Col>
                                            <Col md={9} className="extra_tag">{t('budget.budget-configuration.rental-tax')}</Col>
                                        </Row>
                                        <Row className="columns is-mobile is-vcentered">
                                            <Col md={3} className="price_column"> 
                                                $ <span className="extra_price">{(parseFloat(this.state.taxes.surcharge) * 1).toFixed(2)}</span>
                                            </Col>
                                            <Col md={9} className="extra_tag">{t('budget.budget-configuration.surcharge')}</Col>
                                        </Row>
                                        <Row className="columns is-mobile is-vcentered">
                                            <Col md={3} className="price_column"> 
                                            <span className="extra_price">{this.state.taxes.sales_tax} %</span>
                                            </Col>
                                            <Col md={9} className="extra_tag">{t('budget.budget-configuration.sales-tax')}</Col>
                                        </Row>
                                        </div>
                                        <hr />
                                        {discountedAmount}
                                        <h2 className="value"> $ {this.state.amount.toFixed(2).replace(',', '').replace('.', ',')} </h2>
                                        {coupon}
                                        {proceedButton}
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </section>
            </Budget>
        )
    }
}

const mapStateToProps = ({website, budget}) => ({
    car_category: budget.car_category,
    origin_location: budget.origin_location,
    destination_location: budget.destination_location,
    begin_date: budget.begin_date,
    end_date: budget.end_date,
    begin_time: budget.begin_time,
    end_time: budget.end_time,
    duration: budget.duration,
    coupon: budget.coupon,
    selectedExtras: budget.selectedExtras,
    cities: website.cities,
    exchange_currency: website.exchange_currency,
    currency_hash: website.currency_hash,
    extras: website.extras,
    step: budget.step
});

const mapDispatchToProps = (dispatch) => ({
    setHash: (payload) => dispatch({type: 'CURRENCY_HASH', payload: payload}),
    setExtras: (payload)      => dispatch({type: 'SET_EXTRAS', payload: payload}),
    changeExtra: (payload)   => dispatch({type: 'CHANGE_EXTRAS', payload: payload}),
    changeStep: (payload) => dispatch({type: 'STEP', payload: payload}),
    setSelectedExtras: (payload) => dispatch({type: 'SET_SELECTED_EXTRAS', payload: payload}),
    setFinalBudget: (payload) => dispatch({type: 'SET_FINAL_BUDGET', payload: payload}),
    setCoupon: (payload) => dispatch({type: 'SET_COUPON', payload: payload}),
    removeCoupon: (payload) => dispatch({type: 'REMOVE_COUPON', payload: payload}),
    toggleModal: (payload) => dispatch({type: 'TOGGLE_MODAL', payload: {action: payload.action, children: payload.children}})
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withTranslation()(BudgetConfiguration)));
