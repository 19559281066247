import React from 'react';
import './instagram-card.styles.scss';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


const InstagramCard = (props) => {
    return (
        <div className="instagram-card">
            <div className="card" >
                <figure className="image is-1by1">
                    <img className="card-img-top" src={props.media_url} alt="Card cap" />
                </figure>
                <div className="content-wrapper">
                    <div className="card-content">
                        <a href={props.permalink} target='_blank' rel="noopener noreferrer" >Ver mais no instagram</a>
                        <hr className="instagram-separator" />
                        <div className="media">
                            <div className="media-left">
                                <FontAwesomeIcon icon={['far', 'heart']} />
                                <FontAwesomeIcon icon={['far', 'comment']} />
                            </div>
                            <div className="media-content">
                            </div>
                            <div className="media-right">
                                <FontAwesomeIcon icon={['far', 'bookmark']} />
                            </div>
                        </div>
                        <p className="description"><b>{props.user.username}</b> {props.caption}</p>
                    </div>
                </div>
            </div> 
        </div>
    )
}

export default InstagramCard;