import { TOGGLE_MODAL } from './modal.constants';
import { updateObject } from '../../utilities/utility';

const initialState = {
    modal_open: false,
    show: false
}

const toggleModal = (state, data) => {
    let modalAction = data.payload.action;
	let modalContent = '';
	if (modalAction === 'close') {
		modalAction  = false;
		modalContent = '';
	} else {
		modalAction = true;
		modalContent = data.payload.children;
	}
	return updateObject(state, {modal_open: modalAction, modal_content: modalContent});
}

const modalReducer = (state = initialState, action) => {
    const { type, payload } = action;
    
    switch(type) {
        case TOGGLE_MODAL:
            return toggleModal(state, action);
        default:
            return state;
    }
}

export default modalReducer;