export default {
    translation: {
        "main.call": "Carros para alugar em toda a Florida",
        "instagram.section.title": "Conheça nossa rede social",
        "contact.title": "CONTATO",
        "contact.call": "Tem alguma dúvida?",
        "contact.description": "Entre em contato conosco em qualquer um de nossos canais de comunicação caso necessite de suporte",
        "contact.form.name": "Nome",
        "contact.form.phone": "Telefone",
        "contact.form.message": "Mensagem",
        "contact.form.send": "Enviar",
        "vehicles.title": "Nossas Categorias de Veículos",
        "cities.title": "Atendemos nas maiores cidades da Florida",
        "services.title": "Veja como é fácil alugar com a gente",
        "search.form.receive": "Receber em",
        "search.form.return": "Devolver em",
        "search.form.search": "Buscar",
        "company.title": "NOSSA EMPRESA",
        "location.title": "Localização",
        "location.description": "Estamos localizados na 8350 NW 52nd Terrace, Suite 301 Doral, FL, 33166",
        "checkout-form.personal-information": "Informações pessoais",
        "checkout-form.short-full": "À vista",
        "checkout-form.short-installments": "Parcelar",
        "checkout-form.first_name": "Primeiro nome",
        "checkout-form.last_name": "Sobrenome",
        "checkout-form.email": "E-mail",
        "checkout-form.phone": "Telefone",
        "checkout-form.whatsapp": "WhatsApp",
        "checkout-form.driver_license": "Carteira de Motorista",
        "checkout-form.driver_license_expiry": "Vencimento carteira de Motorista",
        "checkout-form.birthday": "Data de Nascimento",
        "checkout-form.address": "Endereço",
        "checkout-form.nationality": "Nacionalidade",
        "checkout-form.nationality.brazilian": "Brasileiro",
        "checkout-form.nationality.american": "Americano",
        "checkout-form.nationality.other": "Outro",
        "checkout-form.travel_company": "Companhia Aérea",
        "checkout-form.flight_number": "Número do Voo",
        "checkout-form.flight_information": "Informações de Voo",
        "checkout-form.payment.title": "Pagamento",
        "checkout-form.payment.full": "Pagamento à vista em plataforma online",
        "checkout-form.payment.installments": "Faça sua reserva e parcele em até 12x",
        "checkout-form.payment.subtitle": "Por favor, escolha uma  das opções seguintes:",
        "checkout-form.confirm": "CONFIRMAR RESERVA",
        "checkout-form.required-fields": "Preencha todos os campos para prosseguir",
        "checkout-form.terms": "Ver termos e condições",
        "checkout-form.terms-accept": "Estou ciente e de acordo com as cláusulas dos termos e condições gerais de locação",
        "faq": "Perguntas Frequentes",
        "whatsapp_contact": "Contato via WhatsApp",
        "payment": "Pagamento",
        "relationship": "Relacionamento",
        "delivery": "Entrega",
        "payment.description": "Sem altos custos no seu cartão de crédito.",
        "relationship.description": "Para agilizar e tornar mais simples os serviços",
        "delivery.description": "Entrega VIP personalizada com hora marcada",
        "company-content-1": 'Você recebe seu carro logo no desembarque;',
        "company-content-2": 'Falamos português, inglês e espanhol;',
        "company-content-3": 'Não cobramos depósito caução;',
        "company-content-4": 'Carros para todos os gostos;',
        "company-content-5": 'Seguro completo;',
        "company-content-6": 'O melhor preço!',
        "company-info-1": "Sabendo da quantidade de brasileiros que vem para o estado da Flórida anualmente, e que muitas vezes a língua estrangeira dificulta a comunicação com empresas locais, fundamos em 2017, a <strong>VC USA Rent-A-Car</strong>, uma empresa  de locação de carros localizada em Miami, no estado da Flórida.",
        "company-info-2": "Proporcionamos algo além da locação de veículos, contamos com um atendimento personalizado, onde cada cliente recebe o seu carro reservado na área do desembarque do aeroporto, evitando filas, esteiras, trêns ou qualquer tipo de transfer, sem burocracia, economizando tempo e tendo total conveniência em sua viagem.",
        "sns": "Acesse nossas redes sociais",
        "developed_by": "Desenvolvido por",
        "search.empty": 'Não encontramos nenhum resultado para sua pesquisa',
        "budget.step-1.description": "Selecione o veículo desejado para sua locação",
        "budget.step-2.description": "Adicione extras para mais conforto em sua viagem",
        "budget.step-3.description": "Preencha suas informações pessoais para prosseguir",
        "budget.step-4.description": "Finalize sua reserva e realize o pagamento",
        "budget.step-1.title": "Categoria",
        "budget.step-2.title": "Extras",
        "budget.step-3.title": "Informações",
        "budget.step-4.title": "Pagamento",
        "budget.header": "{{origin_location}} até {{destination_location}}, de {{date_from}} às {{begin_time}} até {{date_to}} às {{end_time}}",
        "budget.category.description-manual": "{{category.spots}} lugares, câmbio manual com espaço para {{category.baggages}} bagagens.",
        "budget.category.description-automatic": "{{category.spots}} lugares, câmbio automático com espaço para {{category.baggages}} bagagens.",
        "budget.search.daily": "diária",
        "budget.search.total": "Total de {{total}}",
        "budget.extras.protections.title": "Proteções inclusas",
        "budget.extras.protections.description": "Inclusos no seu pacote estão:",
        "budget.extras.extras.title": "Escolha seus adicionais",
        "budget.extras.extras.description": "Proteções e serviços adicionais de acordo com suas necessidades",
        "budget.extras.extras.more-details": "Ver detalhes",
        "budget.budget-configuration.description": "De {{begin_date}} à {{end_date}}, saindo de {{origin_location}} com retorno em {{destination_location}}",
        "budget.budget-configuration.duration-description": "Duração: {{duration}} dias",
        "budget.budget-configuration.base-value-description": "Valor base: $ {{base_value}}",
        "budget.budget-configuration.payment-details": "Detalhes de Pagamento",
        "budget.budget-configuration.sales-tax": "Taxa de Vendas",
        "budget.budget-configuration.surcharge": "Surcharge",
        "budget.budget-configuration.rental-tax": "Taxa de aluguel",
        "budget.budget-configuration.applied-coupon": "Cupom aplicado:",
        "budget.budget-configuration.apply-coupon": "Aplicar cupom de desconto",
        "budget.budget-configuration.proceed": "CONTINUAR",
        "budget.budget-configuration.coupon.apply": "APLICAR",
        "budget.budget-configuration.coupon.return": "RETORNAR À COMPRA",
        "budget.budget-configuration.coupon.remove": "Remover",
        "budget.budget-configuration.coupon.call": "CUPOM: {{discount_value}}{{discount_type}} DE DESCONTO! ",
        "budget.budget-configuration.coupon.applied-call": "APLICADO!",
        "budget.budget-configuration.coupon.404": "Cupom não encontrado ou expirado",
        "budget.payment.payment": "Pagamento",
        "budget.payment.paypal": "O pagamento e conclusão de sua compra serão feitos através do PayPal. Selecione uma das opções abaixo para que possa realizar o pagamento.",
        "budget.payment.paypal-concluded": "Pagamento concluido! Obrigado por escolher a VC USA Rent-a-Car. Estaremos esperando você na Florida!",
        "budget.payment.ebanx": "O pagamento e conclusão de sua compra serão feitos através do EBANX, em uma nova janela. Clique no botão abaixo para ser redirecionado e realizar o pagamento.",
        "budget.payment.ebanx-make-payment": "Realizar pagamento",
        "budget.payment.loading-payment": "Carregando pagamento...",
        "budget.review.email-1": "Enviamos uma cópia de todos os dados da reserva para seu email.",
        "budget.review.email-2": "Após a confirmação do pagamento, o <strong>{{gateway}}</strong> irá lhe enviar um e-mail com a cópia do recibo.",
        "budget.review.email-3": "Agradecemos a preferência e lhe desejamos uma ótima viagem!",
        "budget.review.title": "Resumo da Locação",
        "budget.review.seats": "{{seats}} Lugares",
        "budget.review.baggages": "{{baggages}} Malas",
        "budget.review.automatic": "Automático",
        "budget.review.manual": "Manual",
        "budget.review.total": "Total a pagar: R$ {{total}}",
        "budget.review.code": "Código da reserva: <strong>{{code}}</strong>",
        "budget.review.date_place": "DATA E LOCAL",
        "budget.review.delivery": "Retirada",
        "budget.review.return": "Devolução",
        "budget.review.date_time": "{{day}}/{{month}}/{{year}}, às {{time}}",
        "budget.review.extras": "ADICIONAIS",
        "budget.review.rent_tax": "Taxa de Aluguel",
        "budget.review.surcharge": "Surcharge",
        "budget.review.sales_tax": "Taxa de Venda",
        "budget.review.customer": "CLIENTE",
        "budget.review.phone": "Telefone",
        "budget.review.whatsapp": "WhatsApp",
        "budget.review.cnh": "Carteira de Motorista",
        "budget.review.email": "E-mail",
        "budget.review.arrival_flight": "Voo de Chegada",
        "budget.review.flight_company": "Companhia",
        "budget.review.flight_number": "Nº Voo",
        "budget.review.undefined": "Não definido",
        "languages.title": "Idiomas",
        "languages.subtitle": "Escolha um idioma para visualizar o site da forma desejada.",
        "faq.title": "Como podemos te ajudar?",
        "faq.subtitle": "Digite no campo de texto acima para filtrar as perguntas frequentes abaixo",
        "faq.question_1": "Que tipo de habilitação precisa apresentar para alugar um carro?",
        "faq.answer_1": "O locatário e todos os motoristas devem ter uma carteira de habilitação válida e original que devem ser apresentados no momento de retirada do carro. Todos os motoristas com uma carteira de habilitação não americana devem apresentar um Passaporte válido.",
        "faq.question_2": "Existe algum valor extra a ser pago na retirado do veículo? ",
        "faq.answer_2": "Não há nenhum valor a ser pago além do que foi informado no ato da reserva.",
        "faq.question_3": "Quantos anos devo ter para locar um carro na VC USA Rent- A- Car?",
        "faq.answer_3": "É preciso ter pelo menos 21 anos. O locatário e todos os outros motoristas devem dispor de uma carteira de habilitação válida e original.",
        "faq.question_4": "Existe depósito caução?",
        "faq.answer_4": "Cobramos depósito caução (Security deposits) a todos os clientes com carteira de motoristas dos Estados Unidos.",
        "faq.question_5": "É necessário cartão de crédito para realizar a locação do veiculo?",
        "faq.answer_5": "Sim, para o cadastro no nosso sistema e eventuais custos que venham ocorrer durante a locação (Multas de trânsito e de estacionamento, tanque de combustível, limpeza do veículo).",
        "faq.question_6": "Posso adicionar motoristas na locação do veículo?",
        "faq.answer_6": "Sim, o valor contratado permite a adição de 1 (um) motorista.",
        "faq.question_7": "Como funcionam os pedágios?",
        "faq.answer_7": "Todos os veículos já possuem o aparelho SunPass instalado, e já é incluso em TODAS as reservas. Você só pagará os pedágios que usou durante sua viagem.",
        "faq.question_8": "Se por acaso eu levar uma multa durante minha viagem?",
        "faq.answer_8": "Nos Estados Unidos as multas de trânsito e de estacionamento são emitidas contra a carteira de habilitação do infrator e não na placa do veículo. As multas de trânsito são de responsabilidade do locatário. O mesmo recebido algum tipo de infração, deve informar a locadora para fazermos o pagamento.",
        "faq.question_9": "Quais são as formas de pagamentos disponíveis na VC USA Rent- A- Car?",
        "faq.answer_9": "Dinheiro em espécie (Dólar); \n Transferência bancária em dólar(Wire Transfer, Zelle, Cashapp, Venmo). \n Cartão de crédito internacional com pagamento em dólar; \n Cartão de crédito nacional podendo parcelar em até 12x com acréscimo da operadora; \n Boleto bancário; \n",
        "faq.question_10": "Como posso efetivar a reserva do meu veículo e como funciona o pagamento?",
        "faq.answer_10": "A reserva do seu veículo é automaticamente efetivada quando o cliente realiza o pagamento diretamente no website, ou com um de nossos atendentes. \n Se preferir pagar em espécie, favor contactar um de nossos atendentes no chat online ou no Whatsapp \n Não fazemos nenhum tipo de bloqueio(Caução) em seu cartão, porém é necessário apresentá-lo por medidas de segurança da empresa.",
        "faq.question_11": "Posso alugar e sair do estado da Flórida?",
        "faq.answer_11": "Não. A circulação com os nossos veículos só está permitido dentro do estado da Flórida.",
        "faq.question_12": "Como retiro o meu veículo?",
        "faq.answer_12": "Nossa equipe irá recebê-lo no estacionamento em frente ao desembarque do aeroporto.  Em sua reserva, já deixaremos tudo alinhado para estarmos na data e horário marcado com pontualidade.",
        "faq.question_13": "O que está incluso na minha locação?",
        "faq.answer_13": "Seu orçamento inclui: \n Seguros obrigatórios para circular na Flórida(acidente/ terceiros / roubo / assistência 24 horas ao veículo – falha mecânica); \n Aparelho SunPass; \n Milhagem livre; \n Um condutor adicional; \n Cadeirinha de criança, se preciso \n Entrega sem custo no seu desembarque no aeroporto. \n",
        "faq.question_14": "O que os seguros cobrem?",
        "faq.answer_14": "Em todas as locações, nós já incluímos o seguro total, o seguro para terceiros e de responsabilidade civil.\nEstes, cobrem qualquer custo de acidente que venha acontecer com o nosso veículo, com o carro de terceiros, como também casas, postes, etc. \n Caso seja necessário acionar o seguro, o cliente se responsabiliza por uma franquia de US$1000.00 pelo veiculo alugado.",
        "faq.question_15": "O que os seguros não cobrem?",
        "faq.answer_15": "Seguros não cobrem os seguintes custos: \n Danos abaixo de US$1000 (arranhões, pequenas mossas, vidros, chaves do carro, etc.) \n Danos devido à corridas e rachas \n Danos decorrentes de guerra, revolução, agitação civil \n Queimados em bancos, carpetes ou tapetes \n Danos que afetem apenas pneus, rodas, suspensão, sistemas de som, ou perda por furto de peças do veículo e danos resultantes destes \n Danos causados à transmissão, rodas, e partes agregadas ao chassi dos veículos, por condução em estradas irregulares \n Danos resultantes da condução em locais onde o tráfego de veículos é proibido, como trilhas, bancos de neve, gelo, rios sem pontes ou riachos, praias, lugares acessíveis apenas na maré baixa, ou outras áreas sem estradas. \n Danos causados por areia, cascalho, cinzas ou outros tipos de material de terra que está sendo soprado sobre o veículo. \n Se o veículo é transportado por mar, nenhuma compensação será paga pelos danos causados pela água do mar/maresia. \n Danos causados por não utilizar combustível correto \n",
    }
};