import React from 'react';
import SearchForm from '../search-form/search-form.component';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import './search-box.styles.scss';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

class SearchBox extends React.Component {
    handleSubmit = () => {
        const {origin_location, destination_location, begin_date, end_date, begin_time, end_time} = this.props;

        let params = {
            origin_location,
            destination_location,
            begin_date: begin_date.format('YYYY-MM-DD'),
            end_date: end_date.format('YYYY-MM-DD'),
            begin_time: begin_time,
            end_time: end_time
        }
        let queryParams = new URLSearchParams(params).toString();

        this.props.history.push({
            pathname: '/s',
            search: queryParams
        });
    }
    render() {
        const {t} = this.props;
        return (
            <div className="search-box landing">
                <Card className="d-none d-sm-none d-md-block shadow-lg">
                <Card.Body>
                    <h1 className="text-left mb-4">{t('main.call')}</h1>
                        <SearchForm handleSubmit={this.handleSubmit}/>
                    </Card.Body>
                </Card>
                <Container className="d-block d-sm-block d-md-none my-4">
                    <SearchForm handleSubmit={this.handleSubmit} />
                </Container>
            </div>
        );
    }
} 

const mapStateToProps = ({budget}) => ({
    origin_location: budget.origin_location,
    destination_location: budget.destination_location,
    begin_date: budget.begin_date,
    end_date: budget.end_date,
    begin_time: budget.begin_time,
    end_time: budget.end_time
});

export default connect(mapStateToProps)(withTranslation()(withRouter(SearchBox)));