import React from 'react';
import { apiBaseUrl } from '../../utilities/config';

const CityInformation = ({website_title, website_subtitle, website_description, img_url}) => (
    <div>
        <h1>{website_title}</h1><small>{website_subtitle}</small> <br></br>
        <img src={apiBaseUrl + img_url} />
        <p>{website_description}</p>
    </div>
);

export default CityInformation;