import React from 'react';
import './modal.styles.scss';
const Modal = ({handleClose, show, children}) => {
  const showHideClass = show ? "modal-wrapper is-active" : "modal-wrapper";
  return (
    <div className={showHideClass}>
      <div className="modal-background" onClick={handleClose}></div>
      <div className={`modal ${showHideClass ? 'show' : ''}`}>
        <div className="modal-dialog modal-lg d-flex justify-content-center align-items-center">
            <div className="modal-content">
                <div className="modal-body">
                    {children}
                </div>
            </div>
        </div>
      </div>
    </div>
  );
}

export default Modal;
