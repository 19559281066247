import React from 'react';
import { connect } from 'react-redux';
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import {
  DateRangePicker
} from "react-dates";
import TimePicker from 'react-time-picker';
import "react-dates/lib/css/_datepicker.css";
import SelectBox from '../select-box/select-box.component';
import "./search-form.styles.scss";
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { withTranslation } from 'react-i18next';

class SearchForm extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        startDate: moment(),
        endDate: moment(),
        beginTime: moment().format('HH:mm'),
        endTime: moment().format('HH:mm'),
        focusedInput: null,
        origin_location: '',
        destination_location: '',
        windowWidth: window.innerWidth
      }
    }
    
    componentDidMount() {
      const {begin_date, end_date, begin_time, end_time, origin_location, destination_location} = this.props;
      
      window.addEventListener('resize', this.handleResize);

      this.setState({
        startDate: begin_date,
        endDate: end_date,
        beginTime: begin_time,
        endTime: end_time,
        origin_location,
        destination_location
      })
    }

    componentWillUnmount() {
      window.removeEventListener('resize', this.handleResize);
    }

    handleResize = () => {
      this.setState({ windowWidth: window.innerWidth });
    }

    changeCity = (value, objectToChange) => {
      let newState = {
        ...this.state
      };
      newState[objectToChange] = value;
      this.setState(newState);
    }

    handleSubmit = async (event) => {
      event.preventDefault();
      
      const { origin_location, destination_location, startDate, endDate, beginTime, endTime } = this.state;
      await this.props.setSearch({
        origin_location,
        destination_location,
        begin_date: startDate,
        end_date: endDate,
        begin_time: beginTime,
        end_time: endTime
      });

      this.props.handleSubmit();
    }

    render() {
        const {className, t} = this.props;
        return (
          <div className={className}>
            <Form onSubmit={this.handleSubmit} inline={this.props.inline}>
              <Form.Group className="">
              { !this.props.inline 
                ? 
                  <InputGroup>
                    <Form.Label
                        className="p-0"
                        style={{ textAlign: "left" }}
                        column
                        xs={6}
                      >
                        {t('search.form.receive')}
                      </Form.Label>
                    </InputGroup>
                    : null
                }

                <InputGroup>
                  <SelectBox 
                    iteratableObject={this.props.cities} 
                    id="origin_location" 
                    selected={this.state.origin_location}
                    handleChange={this.changeCity}
                    className={this.props.inline ? 'inline' : ''}
                  />
                </InputGroup>
              </Form.Group>
              <Form.Group className="">
              { !this.props.inline 
                ? 
                  <InputGroup>
                    <Form.Label
                        className="p-0"
                        style={{ textAlign: "left" }}
                        column
                        xs={6}
                      >
                      {t('search.form.return')}
                      </Form.Label>
                    </InputGroup>
                    : null
                }
                <InputGroup>
                  <SelectBox 
                    iteratableObject={this.props.cities} 
                    id="destination_location" 
                    selected={this.state.destination_location}
                    handleChange={this.changeCity}
                    className={this.props.inline ? 'inline' : ''}
                  />
                </InputGroup>
              </Form.Group>
              <Form.Group className="">
                {
                  !this.props.inline ?
                  <InputGroup>
                    <Form.Label
                      className="p-0"
                      style={{ textAlign: "left" }}
                      column
                      xs={6}
                    >
                      Check-in
                    </Form.Label>
                    <Form.Label
                      className="p-0"
                      style={{ textAlign: "left" }}
                      column
                      xs={6}
                    >
                      Check-out
                    </Form.Label>
                  </InputGroup>
                  : null
                }
                  <DateRangePicker
                    startDate={this.state.startDate} // momentPropTypes.momentObj or null,
                    startDateId="begin_date" // PropTypes.string.isRequired,
                    endDate={this.state.endDate} // momentPropTypes.momentObj or null,
                    endDateId="end_date" // PropTypes.string.isRequired,
                    onDatesChange={({ startDate, endDate }) =>
                      this.setState({ startDate, endDate })
                    } // PropTypes.func.isRequired,
                    focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                    onFocusChange={(focusedInput) =>
                      this.setState({ focusedInput })
                    } // PropTypes.func.isRequired
                    startDatePlaceholderText="Entrega"
                    endDatePlaceholderText="Devolução"
                    orientation={this.state.windowWidth < 992 ? 'vertical' : 'horizontal'}
                    keepOpenOnDateSelect={true}
                    isOutsideRange={(momentDate) => {
                      let baseDate = moment().add(3, 'days');
                      return baseDate.diff(momentDate, 'days') > 0;
                    }}
                    block={!this.props.inline}
                    minimumNights={2}
                  />
                  <div className={`timepicker-wrapper ${this.props.inline ? 'inline' : ''}`}>
                    <TimePicker 
                      value={this.state.beginTime} 
                      format="HH:mm"
                      className={`search-timer ${this.props.inline ? 'inline' : ''}`}
                      disableClock
                      clearIcon={null}
                      onChange={(value) => this.setState({beginTime: value})}
                    />
                    <div className="timepicker-icon">
                    <div class="DateRangePickerInput_arrow DateRangePickerInput_arrow_1" aria-hidden="true" role="presentation"><svg class="DateRangePickerInput_arrow_svg DateRangePickerInput_arrow_svg_1" focusable="false" viewBox="0 0 1000 1000"><path d="M694 242l249 250c12 11 12 21 1 32L694 773c-5 5-10 7-16 7s-11-2-16-7c-11-11-11-21 0-32l210-210H68c-13 0-23-10-23-23s10-23 23-23h806L662 275c-21-22 11-54 32-33z"></path></svg></div>
                    </div>
                    <TimePicker 
                      value={this.state.endTime} 
                      format="HH:mm"
                      className={`search-timer ${this.props.inline ? 'inline' : ''}`}
                      clearIcon={null}
                      disableClock
                      onChange={(value) => this.setState({endTime: value})}
                    />
                  </div>
              </Form.Group>
              <div className="clearfix">
                { this.props.inline
                  ? 
                  <React.Fragment>
                    <Button
                      type="submit"
                      className="float-sm-right inline search-button"
                      ><FontAwesomeIcon icon="search" />
                    </Button>
                  </React.Fragment>
                  : (
                  <Button
                    type="submit"
                    className="float-sm-right"
                    variant="outline-primary"
                    block
                    size="md"
                  >
                  {t('search.form.search')}
                  </Button>
                  )
                }
              </div>
            </Form>
          </div>
        );
    }
}

const mapStateToProps = ({website, budget}) => ({
  cities: website.cities,
  origin_location: budget.origin_location,
  destination_location: budget.destination_location,
  begin_date: budget.begin_date,
  end_date: budget.end_date,
  begin_time: budget.begin_time,
  end_time: budget.end_time
});

const mapDispatchToProps = (dispatch) => ({
  setCity: (payload) => dispatch({ type: 'SET_CITY', payload: payload}),
  setSearch: (payload) => dispatch({ type: 'SET_SEARCH', payload: payload})
})

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(SearchForm));