import React from 'react';
import { PayPalButton } from "react-paypal-button-v2";
import { Api } from '../../services/api';
import { withTranslation } from 'react-i18next';
class PaypalPayment extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      pending: true
    };
  }
  render() {
    const { email, reservationCode, gateway, paymentType, t } = this.props;

    return (
      <div>
        {this.state.pending === true ? (
          <React.Fragment>
          <p>
              {t('budget.payment.paypal')}
              
          </p>
          <PayPalButton
            createOrder={(data, actions) => {
              return Api.post(`resolvePayment`, {
                email,
                reservation_code: reservationCode,
                gateway,
                payment_type: paymentType,
              }).then((response) => {
                return response.data.payment.orderId;
              });
            }}
            onApprove={(data) => {
              return Api.post(`capturePayment`, {
                orderId: data.orderID,
              }).then((response) => {
                if (response.status == 200) {
                  this.setState({
                    loading: false,
                    pending: false,
                  });
                }
              });
            }}
          />
          </React.Fragment>
        ) : (
          <p>{t('budget.payment.paypal-concluded')}</p>
        )}
      </div>
    );
  }
}

export default withTranslation()(PaypalPayment);
