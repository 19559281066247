import React from 'react';
import './payment-confirmation.styles.scss';
import PaypalPayment from '../../components/paypal-payment/paypal-payment.component';
import EbanxPayment from '../../components/ebanx-payment/ebanx-payment.component';
import {connect} from 'react-redux';
import {withTranslation} from 'react-i18next';
class PaymentConfirmation extends React.Component {
    constructor(props) {
        super(props);
    }
    
    componentDidMount() {

    }
     
    render() {
        let payable = '';
        let {reservation_code, email, gateway} = this.props.budget;
        const {t} = this.props;
        switch (gateway) {
            case 'paypal':
                payable = (
                  <PaypalPayment
                    reservationCode={reservation_code}
                    email={email}
                    paymentType={this.props.paymentType}
                    gateway={gateway}
                  />
                );
                break;
            case 'ebanx':
                payable = (
                  <EbanxPayment
                    reservationCode={reservation_code}
                    email={email}
                    paymentType={this.props.paymentType}
                    gateway={gateway}
                  />
                );
                break;
            default:
                payable = 'Loading';
                break;
        }
        
        return (
          <div>
            <h1 className="title">{t('budget.payment.payment')}</h1>
            {payable}
          </div>
        );
    }
}


const mapStateToProps = ({ website, budget }) => {
    return {
        budget: budget.final_budget
    };
};

const mapDispatchToProps = dispatch => {
    return {
        step: (payload) => dispatch({ type: 'STEP', payload: payload })
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation()(PaymentConfirmation));
