export default {
    translation: {
        "main.call": "Alquiler de autos en toda Florida",
        "instagram.section.title": "Conoce nuestra red social",
        "contact.title": "CONTACTO",
        "contact.call": "¿Tienes alguna duda?",
        "contact.description": "Contáctanos en cualquiera de nuestros canales de comunicación si necesitas ayuda.",
        "contact.form.name": "Nombre",
        "contact.form.phone": "Teléfono",
        "contact.form.message": "Mensaje",
        "contact.form.send": "Enviar",
        "vehicles.title": "Nuestras categorías de vehículos",
        "cities.title": "Atendemos en las ciudades más grandes de Florida",
        "services.title": "Vea lo fácil que es alquilar con nosotros",
        "search.form.receive": "Reciba el",
        "search.form.return": "El retorno de la",
        "search.form.search": "Buscar",
        "company.title": "NUESTRA EMPRESA",
        "location.title": "Ubicación",
        "location.description": "Estamos ubicados en 8350 NW 52nd Terrace, Suite 301 Doral, FL, 33166",
        "checkout-form.personal-information": "Informaciones personales",
        "checkout-form.short-full": "A la vista",
        "checkout-form.short-installments": "Compartir",
        "checkout-form.first_name": "Primer nombre",
        "checkout-form.last_name": "Apellido",
        "checkout-form.email": "E-mail",
        "checkout-form.phone": "Teléfono",
        "checkout-form.whatsapp": "WhatsApp",
        "checkout-form.driver_license": "Número de carnet de conducir",
        "checkout-form.driver_license_expiry": "Validad número de carnet de conducir",
        "checkout-form.birthday": "Fecha de Nacimiento",
        "checkout-form.address": "Dirección",
        "checkout-form.nationality": "Nacionalidad",
        "checkout-form.nationality.brazilian": "Brasileño",
        "checkout-form.nationality.american": "Americaño",
        "checkout-form.nationality.other": "Otro",
        "checkout-form.travel_company": "Aerolínea",
        "checkout-form.flight_number": "Número de vuelo",
        "checkout-form.flight_information": "Información del Vuelo",
        "checkout-form.payment.title": "Pago",
        "checkout-form.payment.full": "Pago en efectivo en plataforma en línea",
        "checkout-form.payment.installments": "Haz tu reserva hasta 12x",
        "checkout-form.payment.subtitle": "Por favor, elije alguna de las siguientes opciones:",
        "checkout-form.confirm": "CONFIRMAR RESERVACIÓN",
        "checkout-form.required-fields": "Complete todos los campos para continuar",
        "checkout-form.terms": "Ver términos y condiciones",
        "checkout-form.terms-accept": "Entiendo y estoy de acuerdo con los términos de los términos y condiciones generales de alquiler",
        "faq": "Preguntas frecuentes",
        "whatsapp_contact": "Contacto vía WhatsApp",
        "payment": "Pago",
        "relationship": "Relacionamento",
        "delivery": "Entrega",
        "payment.description": "Sin altos costos en su tarjeta de crédito.",
        "relationship.description": "Para optimizar y simplificar los servicios",
        "delivery.description": "Entrega VIP personalizada con cita previa",
        "company-content-1": 'tienes tu coche justo en el rellano;',
        "company-content-2": 'Hablamos portugués, ingles y español;',
        "company-content-3": 'No cobramos depósito de seguridad;',
        "company-content-4": 'Autos para todos los gustos;',
        "company-content-5": 'Seguro completo;',
        "company-content-6": 'El mejor precio!',
        "company-info-1": "Conociendo la cantidad de brasileños que vienen al estado de Florida anualmente, y que muchas veces el idioma extranjero dificulta la comunicación con las empresas locales, en 2017 fundamos <strong> VC USA Rent-A-Car </strong>, una empresa alquiler de autos ubicado en Miami, Florida.",
        "company-info-2": "ofrecemos algo más allá del alquiler de vehículos, contamos con un servicio personalizado, donde cada cliente recibe su auto reservado en el área de llegadas del aeropuerto, evitando colas, colchonetas, trenes o cualquier tipo de traslado, sin burocracia, ahorrando tiempo y teniendo total comodidad. en tu viaje.",
        "sns": "Accede a nuestras redes sociales",
        "developed_by": "Desarrollado por",
        "search.empty": 'No pudimos encontrar ningún resultado para su búsqueda',
        "budget.step-1.description": "Seleccione el vehículo deseado para su alquiler",
        "budget.step-2.description": "Agregue extras para mayor comodidad en su",
        "budget.step-3.description": "Complete su información personal para continuar",
        "budget.step-4.description": "Finaliza tu reserva y realiza el pago",
        "budget.step-1.title": "Categoría",
        "budget.step-2.title": "Extras",
        "budget.step-3.title": "Información",
        "budget.step-4.title": "Pago",
        "budget.header": "{{origin_location}} hasta {{destination_location}}, en {{date_from}} às {{begin_time}} hasta {{date_to}} a {{end_time}}",
        "budget.category.description-manual": "{{category.spots}} lugares, cambio manual con espacio para {{category.baggages}} equipaje.",
        "budget.category.description-automatic": "{{category.spots}} lugares, caja de cambios automática con espacio para {{category.baggages}} equipaje.",
        "budget.search.daily": "diario",
        "budget.search.total": "Total de {{total}}",
        "budget.extras.protections.title": "Protecciones incluidas",
        "budget.extras.protections.description": "Incluido en su paquete están:",
        "budget.extras.extras.title": "Elige tus adicionales",
        "budget.extras.extras.description": "Protecciones y servicios adicionales según sus necesidades",
        "budget.extras.extras.more-details": "Ver detalles",
        "budget.budget-configuration.description": "En {{begin_date}} a {{end_date}}, dejando {{origin_location}} con devolución en {{destination_location}}",
        "budget.budget-configuration.duration-description": "Duración: {{duration}} dias",
        "budget.budget-configuration.base-value-description": "Valor base: $ {{base_value}}",
        "budget.budget-configuration.payment-details": "Detalles del pago",
        "budget.budget-configuration.sales-tax": "Tasa de ventas",
        "budget.budget-configuration.surcharge": "Surcharge",
        "budget.budget-configuration.rental-tax": "Precio de renta",
        "budget.budget-configuration.applied-coupon": "Cupón aplicado:",
        "budget.budget-configuration.apply-coupon": "Aplicar cupón de descuento",
        "budget.budget-configuration.proceed": "SEGUIR",
        "budget.budget-configuration.coupon.apply": "APLICAR",
        "budget.budget-configuration.coupon.return": "VOLVER A COMPRA",
        "budget.budget-configuration.coupon.remove": "Eliminar",
        "budget.budget-configuration.coupon.call": "CUPÓN: {{discount_value}}{{discount_type}} DE DESCUENTO! ",
        "budget.budget-configuration.coupon.applied-call": "APLICADO!",
        "budget.budget-configuration.coupon.404": "Cupón no encontrado o vencido",
        "budget.payment.payment": "Pago",
        "budget.payment.paypal": "El pago y la finalización de su compra se realizará a través de PayPal. Seleccione una de las opciones a continuación para que pueda realizar el pago.",
        "budget.payment.paypal-concluded": "¡Pago completado! Gracias por elegir VC USA Rent-a-Car. ¡Te esperamos en Florida!",
        "budget.payment.ebanx": "El pago y finalización de su compra se realizará a través de EBANX, en una nueva ventana. Haga clic en el botón de abajo para ser redirigido y realizar el pago.",
        "budget.payment.ebanx-make-payment": "Realizar pago",
        "budget.payment.loading-payment": "Cargando pago...",
        "budget.review.email-1": "Enviamos una copia de todos los detalles de la reserva a su correo electrónico.",
        "budget.review.email-2": "Después de la confirmación del pago, el <strong>{{gateway}}</strong> le enviará un correo electrónico con una copia del recibo.",
        "budget.review.email-3": "¡Le agradecemos su preferencia y le deseamos un gran viaje!",
        "budget.review.title": "Resumen de alquiler",
        "budget.review.seats": "{{seats}} Lugares",
        "budget.review.baggages": "{{baggages}} Pantalón",
        "budget.review.automatic": "Automático",
        "budget.review.manual": "Manual",
        "budget.review.total": "Total a pagar: R$ {{total}}",
        "budget.review.code": "Código de reservación: <strong>{{code}}</strong>",
        "budget.review.date_place": "FECHA Y LUGAR",
        "budget.review.delivery": "Retirada",
        "budget.review.return": "Devolución",
        "budget.review.date_time": "{{day}}/{{month}}/{{year}}, a {{time}}",
        "budget.review.extras": "ADICIONALES",
        "budget.review.rent_tax": "Precio de renta",
        "budget.review.surcharge": "Surcharge",
        "budget.review.sales_tax": "Tasa de ventas",
        "budget.review.customer": "CLIENTE",
        "budget.review.phone": "Teléfono",
        "budget.review.whatsapp": "WhatsApp",
        "budget.review.cnh": "Número de carnet de conducir",
        "budget.review.email": "E-mail",
        "budget.review.arrival_flight": "vuelo de llegada",
        "budget.review.flight_company": "Aerolínea",
        "budget.review.flight_number": "Número de vuelo",
        "budget.review.undefined": "No definido",
        "languages.title":"Idiomas",
        "languages.subtitle": "Elija un idioma para ver el web de la forma deseada.",
        "faq.title": "Como podemos ayudarte?",
        "faq.subtitle": "Escriba en el campo de texto arriba para filtrar las preguntas frecuentes abajo",
        "faq.question_1": "¿Qué tipo de licencia necesita presentar para alquilar un coche?",
        "faq.answer_1": "El arrendatario y todos los conductores deben tener una licencia de conducir válida y original que se debe presentar en el momento de la recogida. Todos los conductores con una licencia de conducir no estadounidense deben presentar un pasaporte válido.",
        "faq.question_2": "¿Hay algún importe extra a pagar al recoger el vehículo? ",
        "faq.answer_2": "No hay cantidad a pagar más que la informada en el momento de la reserva.",
        "faq.question_3": "¿Qué edad debo tener para alquilar un auto en VC USA Rent-A-Car?",
        "faq.answer_3": "Debes tener al menos 21 años. El arrendatario y todos los demás conductores deben tener una licencia de conducir válida y original.",
        "faq.question_4": "¿Hay un depósito de seguridad?",
        "faq.answer_4": "Cobramos depósitos de seguridad de todos los clientes con una licencia de conducir del EE. UU. ",
        "faq.question_5": "¿Se requiere tarjeta de crédito para alquilar un vehículo?",
        "faq.answer_5": "Sí, para el registro en nuestro sistema y cualquier costo que pueda ocurrir durante el arrendamiento (multas de tráfico y estacionamiento, tanque de combustible, limpieza del vehículo).",
        "faq.question_6": "¿Puedo agregar conductores al alquiler de vehículos?",
        "faq.answer_6": "Sí, el monto contratado permite la adición de 1 (un) conductor.",
        "faq.question_7": "¿Cómo funcionan los peajes?",
        "faq.answer_7": "Todos los vehículos ya tienen instalado el dispositivo SunPass y ya están incluidos en TODAS las reservas. Solo pagará los peajes que utilizó durante su viaje.",
        "faq.question_8": "¿Si obtengo un boleto durante mi viaje?",
        "faq.answer_8": "En los Estados Unidos, las multas de tráfico y estacionamiento se emiten contra la licencia de conducir y no en la placa. Las multas de tráfico son responsabilidad del arrendatario. El mismo recibió algún tipo de infracción, deberá informar a la empresa de alquiler para realizar el pago.",
        "faq.question_9": "¿Qué formas de pago están disponibles en VC USA Rent-A-Car?",
        "faq.answer_9": "Efectivo (dólar); \ n Transferencia bancaria en dólares (transferencia bancaria, Zelle, Cashapp, Venmo). \ n Tarjeta de crédito internacional con pago en dólares; \ n La tarjeta de crédito nacional se puede dividir hasta en 12 cuotas con la adición del operador; \ n Boleto Bancário; \ n",
        "faq.question_10": "¿Cómo puedo reservar mi vehículo y cómo funciona el pago?",
        "faq.answer_10": "La reserva de su vehículo se realiza automáticamente cuando el cliente realiza el pago directamente en la web, o con uno de nuestros asistentes. \ n Si prefiere pagar en efectivo, comuníquese con uno de nuestros asistentes en el chat en línea o en Whatsapp \ n No realizamos ningún tipo de candado (depósito) en su tarjeta, sin embargo es necesario presentarla por medidas de seguridad de la empresa.",
        "faq.question_11": "¿Puedo alquilar y salir del estado de Florida?",
        "faq.answer_11": "No. Conducir con nuestros vehículos solo está permitido dentro del estado de Florida.",
        "faq.question_12": "¿Cómo recojo mi vehículo?",
        "faq.answer_12": "Nuestro equipo le dará la bienvenida en el estacionamiento frente a la sala de llegadas del aeropuerto. En tu reserva, dejaremos todo alineado para estar en la fecha y hora marcada con puntualidad.",
        "faq.question_13": "¿Qué incluye mi alquiler?",
        "faq.answer_13": "Su presupuesto incluye: \ n Seguro obligatorio para viajar en Florida (accidente / terceros / robo / asistencia del vehículo las 24 horas - falla mecánica); \ n dispositivo SunPass; \ n Kilometraje gratuito; \ n Un controlador adicional; \ n Asiento para niños, si es necesario \ n Entrega gratuita a la llegada al aeropuerto. \ n",
        "faq.question_14": "¿Qué cubre el seguro?",
        "faq.answer_14": "En todos los alquileres, ya incluimos seguro a todo riesgo, seguro de responsabilidad civil y terceros. \ NEstos cubren cualquier coste de accidente que pueda ocurrir con nuestro vehículo, con el coche de terceros, así como casas, postes, etc. . \ n Si es necesario activar el seguro, el cliente es responsable de un deducible de US $ 1000.00 por el vehículo alquilado.",
        "faq.question_15": "¿Qué no cubre el seguro?",
        "faq.answer_15": "El seguro no cubre los siguientes costos: \ n Daños por debajo de $ 1000 (rasguños, pequeñas abolladuras, ventanas, llaves del auto, etc.) \ n Daños debido a carreras y grietas \ n Daños por guerra, revolución, disturbios civiles \ n Quemados en asientos, alfombras o tapetes \ n Daños que afectan solo a neumáticos, ruedas, suspensión, sistemas de sonido o pérdida debido al robo de partes del vehículo y daños resultantes de estos \ n Daños causados ​​a la transmisión, ruedas y partes agregadas al chasis de vehículos, conducción en carreteras irregulares \ n Daños resultantes de conducir en lugares donde el tráfico de vehículos está prohibido, como senderos, bancos de nieve, hielo, ríos sin puentes o arroyos, playas, lugares accesibles solo durante la marea baja u otras áreas sin caminos. \ n Daños causados ​​por arena, grava, ceniza u otro tipo de material terrestre que se proyecta sobre el vehículo. \ n Si el vehículo se transporta por mar, no se pagará ninguna compensación por los daños causados ​​por el agua de mar o el aire salado. \ n Daños causados ​​por no usar el combustible correcto \ n",
    }
};