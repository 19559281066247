export default {
    translation: {
        "main.call": "Cars for rental all around Florida",
        "instagram.section.title": "VISIT OUR SOCIAL NETWORK",
        "contact.title": "CONTACT US",
        "contact.call": "Do you have any questions?",
        "contact.description": "Contact us at any of our communications channels in case you need support",
        "contact.form.name": "Name",
        "contact.form.phone": "Phone",
        "contact.form.message": "Message",
        "contact.form.send": "Send",
        "vehicles.title": "With the best fleet in the market",
        "cities.title": "Rent in Florida's biggest cities",
        "services.title": "And the best services to be offered",
        "search.form.receive": "Pick up at",
        "search.form.return": "Return on",
        "search.form.search": "Search",
        "company.title": "GET TO KNOW US",
        "location.title": "FIND US",
        "location.description": "We're located at 8350 NW 52nd Terrace, Suite 301 Doral, FL, 33166",
        "checkout-form.personal-information": "Personal information",
        "checkout-form.short-full": "One-time payment",
        "checkout-form.short-installments": "Installments",
        "checkout-form.first_name": "First name",
        "checkout-form.last_name": "Last name",
        "checkout-form.email": "E-mail",
        "checkout-form.phone": "Phone",
        "checkout-form.whatsapp": "WhatsApp",
        "checkout-form.driver_license": "Driver License",
        "checkout-form.driver_license_expiry": "Driver License Expiration",
        "checkout-form.birthday": "Birthday",
        "checkout-form.address": "Address",
        "checkout-form.nationality": "Nationality",
        "checkout-form.nationality.brazilian": "Brazilian",
        "checkout-form.nationality.american": "American",
        "checkout-form.nationality.other": "Others",
        "checkout-form.travel_company": "Travel Company",
        "checkout-form.flight_number": "Flight Number",
        "checkout-form.flight_information": "Flight information",
        "checkout-form.payment.title": "Payment",
        "checkout-form.payment.subtitle": "Please, choose one of the following options:",
        "checkout-form.payment.full": "One-time payment",
        "checkout-form.payment.installments": "Confirm your reservation with up to 12 installments (Non-american credit card)",
        "checkout-form.confirm": "CONFIRM RESERVATION",
        "checkout-form.required-fields": "Fill the required fields to proceed",
        "checkout-form.terms": "See terms and conditions",
        "checkout-form.terms-accept": "I'm aware and I agree with the terms and conditions of the contract",
        "faq": "F.A.Q",
        "whatsapp_contact": "Contact with WhatsApp",
        "payment": "Payment",
        "relationship": "Relationship",
        "delivery": "Delivery",
        "payment.description": "No abusive charges in your credit card.",
        "relationship.description": "To make our services fast and simple.",
        "delivery.description": "VIP delivery tailored to your needs and schedule.",
        "company-content-1": "Get your car in airport's arrivals;",
        "company-content-2": 'We speak portuguese, english and spanish;',
        "company-content-3": "No security deposit charges;",
        "company-content-4": "All types of cars;",
        "company-content-5": 'Full coverage insurance included;',
        "company-content-6": 'The best price available!',
        "company-info-1": "Knowing the volume of people that anually travels to Florida, and that in many cases a foreign language difficults communication with local companies, in 2017 VC USA Rent-A-Car was founded, a car rental company located in Miami, FL.",
        "company-info-2": "We bring you something more than just car rental; We have a tailored VIP support, where each customer gets their car at their designated airport arrival location, avoiding queues, trains, or any kind of transfers, with no bureaucracy, saving time and adding comfort to your trip.",
        "sns": "Find us on our social media",
        "developed_by": "Developed by",
        "search.empty": 'No results were found for your search parameters.',
        "budget.step-1.description": "Select your intended vehicle",
        "budget.step-2.description": "Add extras for more comfort in your trip",
        "budget.step-3.description": "Fill your personal informations to proceed",
        "budget.step-4.description": "Finish your reservation and make the payment",
        "budget.step-1.title": "Category",
        "budget.step-2.title": "Extras",
        "budget.step-3.title": "Informations",
        "budget.step-4.title": "Payment",
        "budget.header": "{{origin_location}} to {{destination_location}}, from {{month_from}}/{{day_from}}/{{year_from}} at {{begin_time}} to {{month_to}}/{{day_to}}/{{year_to}} at {{end_time}}",
        "budget.category.description-manual": "{{category.spots}} seats, manual engine with space for up to {{category.baggages}} baggages.",
        "budget.category.description-automatic": "{{category.spots}} seats, automatic engine with space for up to {{category.baggages}} baggages.",
        "budget.search.daily": "daily",
        "budget.search.total": "Total of {{total}}",
        "budget.extras.protections.title": "Included protections",
        "budget.extras.protections.description": "Included in your package are:",
        "budget.extras.extras.title": "Choose your extras",
        "budget.extras.extras.description": "Additional protections and services tailored for your own necessities",
        "budget.extras.extras.more-details": "See detals",
        "budget.budget-configuration.description": "From {{month_begin}}/{{day_begin}}/{{year_begin}} to {{month_end}}/{{day_end}}/{{year_end}}, picking up in {{origin_location}} and returning in {{destination_location}}",
        "budget.budget-configuration.duration-description": "Duration: {{duration}} days",
        "budget.budget-configuration.base-value-description": "Base value: $ {{base_value}}",
        "budget.budget-configuration.payment-details": "Payment details",
        "budget.budget-configuration.sales-tax": "Sales fee",
        "budget.budget-configuration.surcharge": "Surcharge",
        "budget.budget-configuration.rental-tax": "Rental fee",
        "budget.budget-configuration.applied-coupon": "Applied coupon:",
        "budget.budget-configuration.apply-coupon": "Apply discount coupon",
        "budget.budget-configuration.proceed": "CONTINUE",
        "budget.budget-configuration.coupon.apply": "APPLY",
        "budget.budget-configuration.coupon.return": "RETURN TO ORDER",
        "budget.budget-configuration.coupon.remove": "Remove",
        "budget.budget-configuration.coupon.call": "COUPON: {{discount_value}}{{discount_type}} OFF! ",
        "budget.budget-configuration.coupon.applied-call": "APPLIED!",
        "budget.budget-configuration.coupon.404": "Coupon not found or expired",
        "budget.payment.payment": "Payment",
        "budget.payment.paypal": "The payment and conclusion of your order will be done through PayPal. Select one of the options below to make your payment.",
        "budget.payment.paypal-concluded": "Payment finished! Thank you for choosing VC USA Rent-a-Car. We'll be waiting for you at Florida!",
        "budget.payment.ebanx": "The payment and conclusion of your order will be done through EBANX in a new window. Click on the button below to be redirected and make the payment.",
        "budget.payment.ebanx-make-payment": "Pay",
        "budget.payment.loading-payment": "Loading payment...",
        "budget.review.email-1": "An E-mail with your rent's information was sent to your inbox.",
        "budget.review.email-2": "After your payment has been confirmed, <strong>{{gateway}}</strong> will send you and e-mail with a copy of your receipt.",
        "budget.review.email-3": "We appreciate your preference and wish you a nice trip!",
        "budget.review.title": "Rent review",
        "budget.review.seats": "{{seats}} Seats",
        "budget.review.baggages": "{{baggages}} Baggages",
        "budget.review.automatic": "Automatic",
        "budget.review.manual": "Manual",
        "budget.review.total": "Payment total: R$ {{total}}",
        "budget.review.code": "Reservation code: <strong>{{code}}</strong>",
        "budget.review.date_place": "DATE AND PLACE",
        "budget.review.delivery": "Retrieval",
        "budget.review.return": "Devolution",
        "budget.review.date_time": "{{month}}/{{day}}/{{year}}, at {{time}}",
        "budget.review.extras": "EXTRAS",
        "budget.review.rent_tax": "Rental fee",
        "budget.review.surcharge": "Surcharge",
        "budget.review.sales_tax": "Sales fee",
        "budget.review.customer": "CUSTOMER",
        "budget.review.phone": "Telephone",
        "budget.review.whatsapp": "WhatsApp",
        "budget.review.cnh": "Driver's License",
        "budget.review.email": "E-mail",
        "budget.review.arrival_flight": "Arrival flight",
        "budget.review.flight_company": "Company",
        "budget.review.flight_number": "Flight number",
        "budget.review.undefined": "Undefined",
        "languages.title": "Languages",
        "languages.subtitle": "Choose your preferred language to visualize the website",
        "faq.title": "How can we help you?",
        "faq.subtitle": "Type in the textbox above to filter through our answered questions",
        "faq.question_1": "What type of license do I need to rent a car?", 
        "faq.answer_1": "The renter and all drivers need a valid and original license, which need to be presented when picking up the rented car. All drivers with a non-american license must present a valid passport.",
        "faq.question_2": "Are there any extra payments to be made when picking up the vehicle? ",
        "faq.answer_2": "There are no extra payments other than what was stated in the moment of reservation.",
        "faq.question_3": "How old should I be to rent a vehicle with VC USA Rent-a-Car?",
        "faq.answer_3": "You need to be at least 21 years old. The renter and any drivers must have a valid and original driver license.",
        "faq.question_4": "Is there a security deposit?",
        "faq.answer_4": "We charge for security deposits from all customers with a US driver's license.",
        "faq.question_5": "Do I need to have a credit card to rent with VC USA?",
        "faq.answer_5": "Yes, for eventual costs that might occur in your rent (Traffic and parking tickets, gas tank, cleaning fees), we need to register your credit card.",
        "faq.question_6": "Can I have additional driver?",
        "faq.answer_6": "Yes, the value of your rent covers for 1 (one) additional driver.",
        "faq.question_7": "How are tolls handled?",
        "faq.answer_7": "All vehicles are installed with the SunPass device, included in ALL reservations. You will only pay the tolls you used during your trip.",
        "faq.question_8": "What if I get a ticket during my reservation period?",
        "faq.answer_8": "In the United States, parking and traffic tickets are issued against the driver's license, and not the vehicle's license plate. All drivers are accountable towards their received tickets. If the renter receives any kind of infraction, you must inform the rental company so the payment can be made.",
        "faq.question_9": "What payment methods are available in VC USA Rent-A-Car?",
        "faq.answer_9": "Cash (Dollar); \n Bank Transfer in Dollar (Wire Transfer, Zelle, CashApp, Venmo). \n National/International debit/credit card paid in dollar; \n Foreign credit card, with up to 12 installments (interest might be charged) through Ebanx; \n Bank slip or deposit; \n",
        "faq.question_10": "How can I confirm my car reservation and how does payment work?",
        "faq.answer_10": "Your vehicle reservation is automatically confirmed when the customer pays in our webkitRequestAnimationFrame, or with one of our attendants. \n If you prefer to pay in cash, please contact one of our attendants in our on-line chat or WhatsApp \n We don't charge you for security deposits, but it's necessary to present it for security reasons.",
        "faq.question_11": "Can I leave Florida with the vehicle during the reservation period?",
        "faq.answer_11": "No. Our vehicles are only available to rent and drive inside the state of Florida.",
        "faq.question_12": "How do I pick-up my vehicle?",
        "faq.answer_12": "Our staff will welcome you in the parking lot in front of the airport arrivals. With your reservation, everything will be aligned so we can welcome you with punctuality in the agreed date and time.",
        "faq.question_13": "What's included in my reservation?", 
        "faq.answer_13": "Your reservation includes: \n Required insurance to drive in Florida (accidents / theft / 24h assistance for mechanical failure); \n SunPass device; \n Unlimited mileage; \n One additional conductor; \n Child seat, if needed; \n Cost-free vehicle delivery on arrival at the airport. \n",
        "faq.question_14": "What does insurance cover?", 
        "faq.answer_14": "In all reservations are included: total insurance, third party and liability insurance. \n These insurances covers any accident costs that might occur with our vehicle, with third party vehicles, and also houses, poles, etc. \n If the insurance's use is necessary, the customer is responsible for a US$1000.00 payment for the rented vehicle.",
        "faq.question_15": "What does insurance not cover?",
        "faq.answer_15": "Insurances aren't responsible for the following costs: \n Damage below US$1000 (scratches, small dents, glass, car keys, etc.) \n Damages from car races \n Damages from war, revolution or civil unrest \n Seat or carpet burns \n Tires, suspension and sound system damages or loss of vehicle parts by theft and damages resulting from those \n Damages to transmission, tires, and aggregated chassi parts caused by driving in uneven or irregular roads \n Damages resulting from driving the vehicle where traffic is prohibited, like trails, snow banks, ice, bridgeless rivers or streams, beaches, places accessible only during low tides, or other areas with no roads. \n Damages caused by sand, gravel, ashes or any other type of ground material that is being blown against the vehicle. \n If the vehicle is transported though the sea, no compensation will be paid by the damages caused by the sea water or salted air. \n Damages caused by not using the correct type of fuel \n",
    }
};