import React from 'react';
import './footer.styles.scss';
import Logo from '../../assets/logo.svg';
import Instagram from '../../assets/icons/floridaInstagram.png';
import Facebook from '../../assets/icons/floridaFacebook.png';
import SVG from 'react-inlinesvg';

import { useTranslation } from 'react-i18next';

const Footer = ( props ) => {
  const { t }  = useTranslation();
  return (
    <div>
		<footer className="footer main-footer">
			<div className="row justify-content-center">
				<div className="col-xs-8 col-sm-10 align-middle">
					<div className="row align-middle justify-content-center">
						<div className="col-xs-12 col-sm-2">
							<div className="image-container">
								<SVG 
									src={Logo} 
									fill="#fff"
									preProcessor={code => code.replace(/fill:#[A-Fa-f0-9]+/g, 'fill:rgb(255,255,255);')}
								/> 
							</div>
						</div>
						<div className="col-xs-12 col-sm-4">
							<div className="text-container">
								<p>{t('sns')}:</p>
								<div className="links-container">
									<a href="https://www.instagram.com/vcusarentacar/" target="_blank" rel="noopener noreferrer"> <img src={Instagram} alt="Rental Instagram" /> </a>
									<a href="https://www.facebook.com/VCUSA.RentalCar/" target="_blank" rel="noopener noreferrer"> <img src={Facebook} alt="Rental Facebook"/> </a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</footer>
		<footer className="footer sub-footer">
			© 2020 – VC USA · {t('developed_by')} <a href="http://www.interdev.com.br" target="_blank" rel="noopener noreferrer"> <b>Interdev</b> </a>
		</footer>
    </div>
  )
}

export default Footer;
