import React from 'react';

import FormInput from '../form-input/form-input.component';
import CustomButton from '../custom-button/custom-button.component';

import './sign-up.styles.scss';

class SignIn extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      password: '',
      confirmPassword: '',
      cellphone: ''
    };
  }

  handleSubmit = event => {
    event.preventDefault();

    this.setState({ email: '', password: '', confirmPassword: '', cellphone: '' });
  };

  handleChange = event => {
    const { value, name } = event.target;

    this.setState({ [name]: value });
  };

  render() {
    return (
      <div className='sign-in'>
        <h2>Não possuo uma conta</h2>
        <span>Entre com as informações abaixo para se registrar</span>

        <form onSubmit={this.handleSubmit}>
          <div  className="form-box">
            <FormInput
              name='email'
              type='email'
              handleChange={this.handleChange}
              value={this.state.email}
              label='email'
              required
            />
            <FormInput
              name='password'
              type='password'
              value={this.state.password}
              handleChange={this.handleChange}
              label='senha'
              required
            />
            <FormInput
              name='confirm_password'
              type='password'
              value={this.state.password}
              handleChange={this.handleChange}
              label='confirmar senha'
              required
            />
            <FormInput
              name='cellphone'
              type='text'
              value={this.state.password}
              handleChange={this.handleChange}
              label='celular'
              required
            />
          </div>
          <div className="control-buttons">
            <CustomButton type='submit'> Registrar </CustomButton>
            <a onClick={() => this.props.switchComponent('login')}> Já possui uma conta? Logar </a>
          </div>

        </form>
      </div>
    );
  }
}

export default SignIn;
