import React from 'react';
import Form from 'react-bootstrap/Form';
import './select-box.styles.scss';
class SelectBox extends React.Component {
    render() {
        const { iteratableObject, id, selected, handleChange, className} = this.props;
        return(
            <Form.Control 
                id={id} 
                as="select"
                value={selected}
                onChange={(event) => handleChange(event.target.value, id)}
                className={className}
            >
                <option key="" value=""></option>
            {iteratableObject.map(object => (
                <option key={object.id} value={object.id}>{object.name}</option>
                )
            )}
            </Form.Control>
        );
    }
}

export default SelectBox;