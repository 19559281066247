import React from 'react';
import SliderContainer from '../../components/slider-container/slider-container.component';
import { connect } from 'react-redux';

import './vehicles.styles.scss';

import { withTranslation } from 'react-i18next';

class Vehicles extends React.Component  {
    render() {
        const { t } = this.props;
        return(
            <section className="vehicles">
                <h2 className="text-center">{t('vehicles.title')}</h2>
                <SliderContainer 
                    length={this.props.categories.length} 
                    iteratableObject={this.props.categories} 
                    width={16}
                    height={6}
                    visible={3}
                    classType={'name-top'}
                    objectType='vehicle'
                />
            </section>
        )
    }
}

const mapStateToProps = state => {
    return {
        categories: state.website.categories
    }
}

const mapDispatchToProps = dispatch => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Vehicles));