import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import En from './i18n/en';
import ptBr from './i18n/pt-BR';
import esES from './i18n/es-ES';
import { connect } from 'react-redux';

// the translations
// (tip move them in a JSON file and import them)
const resources = {
  en: {
    ...En
  },
  ptBR: {
      ...ptBr
  },
  esES: {
    ...esES
  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "ptBR",

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

  export default i18n;