import React from 'react';
import './cities.styles.scss';
import SliderContainer from '../../components/slider-container/slider-container.component';
import { importAll } from '../../utilities/utility';
import { connect } from 'react-redux';

import { withTranslation } from 'react-i18next';

class Cities extends React.Component {
    render() {
        const { t } = this.props;
        return(
            <section className="cities">
                <h3 className="text-center">{t('cities.title')}</h3>
                <SliderContainer 
                    length={(this.props.cities).filter(item => item).length} 
                    iteratableObject={this.props.cities} 
                    width={16}
                    height={6}
                    visible={2}
                    classType='name-overlay'
                    objectType='city'
                />
            </section>
        )
    }
}

const mapStateToProps = state => {
    return {
        cities: state.website.cities
    }
}

const mapDispatchToProps = dispatch => {
    return {
        toggleModal: (action) => dispatch({type: "TOGGLE_MODAL", payload: {action: action.action, children: action.children}})
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Cities))