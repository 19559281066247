import React from 'react';

import SignIn from '../../components/sign-in/sign-in.component';
import SignUp from '../../components/sign-up/sign-up.component';
import { importAll } from '../../utilities/utility';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Base from '../base/base.component';

import './authentication.styles.scss';

class Authentication extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentComponent: 'login'
    }
  }

  switchComponent = (component) => {
    this.setState({
      ...this.state,
      currentComponent: component
    })
  }

  render() {
    let showComponent = '';
    
    switch (this.state.currentComponent) {
      case 'login':
        showComponent = <SignIn switchComponent={this.switchComponent} />
        break;
      case 'register':
        showComponent = <SignUp switchComponent={this.switchComponent} />
        break;
      default:
        showComponent = <SignIn switchComponent={this.switchComponent} />
    }

    const images = importAll(require.context('../../assets/backgrounds', false, /\.(png|jpe?g|svg)$/));
    return (
      <Base header={{bgType: 'transparent', layout: 'homepage', links: true}} bgVariant="#000">
      <div 
        className="bg-image"
        style={{
          background: `url(${images['background_04.jpg']})`
        }}
      />
        <Container className="authentication">
          <Card>
            <Card.Body>
              {showComponent}
            </Card.Body>
          </Card>
        </Container>
    </Base>
    )
  }
}

export default Authentication;
