import React from 'react';
import './content-with-image.styles.scss';

import { useTranslation } from 'react-i18next';

const ContentWithImage = ({direction, bgColor, description, hasMap, map, image }) => {
  const {t} = useTranslation();
  
  return (
  <div className={`content-with-image ${direction}`} >
    <div className="content-container">
      <div className="text-content">{t(description)}</div>
      <div className="extra-content">
        {hasMap 
          ? <iframe
              src={map} width="634" height="366" frameBorder="0" style={{ border: 0 }} allowFullScreen title="florida_map" 
            />
          : <div className="img-container">
            <img className="img-fluid" alt="company" src={image} />
          </div>
        }
      </div>
    </div>
  </div>  
);}

export default ContentWithImage;