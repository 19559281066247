import React from 'react';
import { NavHashLink as NavigationLink } from 'react-router-hash-link';
import './nav-link.styles.scss';
const NavLink = ({link, children}) => {
    return(
    <NavigationLink 
        to={link} 
        scroll={el => el.scrollIntoView({ behavior: 'smooth', block: 'start' })} 
        className="mr-3 nav-link">
        {children}
    </NavigationLink>
    );
}


export default NavLink;