import React from 'react';

import Header from '../../components/header/header.component';
import Footer from '../../components/footer/footer.component';
import { connect } from 'react-redux';

class Base extends React.Component {
    render() {
        const {header, children, footer, bgVariant} = this.props;
        return (
            <div className="main"
                style={{backgroundColor: bgVariant}}
            >
                <Header {...header} />
                {children}
                {
                    footer 
                    ? <Footer />
                    : null
                }
            </div>
        );
    }
}

const mapStateToProps = ({website}) => ({
    uiMode: website.uiMode
});

export default connect(mapStateToProps)(Base);