import React, { Component } from 'react';
import './contacts.styles.scss';
import ListIcon from '../../components/list-icon/list-icon.component';
import {Api} from '../../services/api';
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import CardImage from '../../assets/contact_blur.jpg';
import Pattern from '../../assets/patterns/pattern.svg';
import Container from 'react-bootstrap/Container';
import FormInput from '../../components/form-input/form-input.component';
import CustomButton from '../../components/custom-button/custom-button.component';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';

import { withTranslation } from 'react-i18next';

class Contacts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      email: '',
      cellphone: '',
      message: ''
    }
  }

  sendMail = () => {
    let payload = {
      'name': this.state.name,
      'message': this.state.message + '. E-mail: ' + this.state.email + '; Telefone: ' + this.state.phone,
      'email': this.state.email
    }
    Api.post('mail', payload)
      .then(response => {
         if (response.status === 200) {
           alert('E-mail enviado com sucesso');
         }
      });
    
  }

  handleChange = event => {
    const { value, name } = event.target;

    this.setState({ [name]: value });
  };


  render() {
    const { t } = this.props;
    return (
      <section className="contacts" id="contacts">
        <div className="callout pattern d-none d-sm-none d-md-block"
  
        >
          <h2 className="text-center">{t('contact.title')}</h2>
        </div>
        <div className="filler pattern"
        >
            <Card className="contact-box">
                <Row>
                    <Col md={6} xs={12}>
                    <div className="card-bg" 
                    style={{
                        backgroundImage: `url(${CardImage})`
                        
                    }}
                    />
                    <Card.Body className="content">
                        <Container fluid>
                            <h3>{t('contact.call')}</h3>
                            <p style={{marginBottom: '2rem'}}>{t('contact.description')}</p>
                            <b>
                            <ul className="fa-ul">
                              <ListIcon icon={['fab', 'whatsapp']} description="+1 (425) 523-5343" className="svg-green mb-3" />
                              <ListIcon icon="envelope" description="contato@vcusarental.com" className="svg-red mb-3" />
                              <ListIcon icon="phone-square" description="+1 (425) 523-5343" className="svg-red mb-3" />
                            </ul>
                        </b>
                        </Container>
                    </Card.Body>
                    </Col>
                    <Col md={6} xs={12} className="d-flex align-items-center">
                        <Container fluid>
                            <Form onSubmit={this.handleSubmit}>
                            <div  className="form-box">
                                <Form.Group className="mb-3">
                                    <InputGroup>
                                    <Form.Label className='p-0' style={{textAlign: 'left'}} column xs={6}>{t('contact.form.name')}</Form.Label>
                                    </InputGroup>
                                    <InputGroup>
                                        <Form.Control/>
                                    </InputGroup>
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <InputGroup>
                                    <Form.Label className='p-0' style={{textAlign: 'left'}} column xs={6}>E-mail</Form.Label>
                                    </InputGroup>
                                    <InputGroup>
                                        <Form.Control/>
                                    </InputGroup>
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <InputGroup>
                                    <Form.Label className='p-0' style={{textAlign: 'left'}} column xs={6}>{t('contact.form.phone')}</Form.Label>
                                    </InputGroup>
                                    <InputGroup>
                                        <Form.Control/>
                                    </InputGroup>
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <InputGroup>
                                        <Form.Label className='p-0' style={{textAlign: 'left'}} column xs={6}>{t('contact.form.message')}</Form.Label>
                                    </InputGroup>
                                    <InputGroup>
                                        <Form.Control as="textarea" rows="5"/>
                                    </InputGroup>
                                </Form.Group>
                                    <Button className='float-sm-right' variant="outline-primary" size="md" block>
                                    {t('contact.form.send')}
                                    </Button>
                            </div>
                        </Form>
                        </Container>
                    </Col>
                </Row>
            </Card>
        </div>
      </section>
    ); 
  }
};

export default withTranslation()(Contacts);
