import React from 'react';
import './instagram.styles.scss';

import SliderContainer from '../../components/slider-container/slider-container.component';

import { Api } from '../../services/api';
import { withTranslation } from 'react-i18next';

class Instagram extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            media: [],
            user: []
        }
    }

    componentDidMount() {
        Api.get(`instagram`)
            .then(response => {
                if (response.status === 200) {
                    if ('media' in response.data && 'user' in response.data) {
                        this.setState({media: response.data.media, user: response.data.user});
                    }
                }
            })
            .catch(err => {
                console.log(err);
            })
    }

    render() {
        const { t } = this.props;
        return(
            <section className="instagram">
                <h3 className="text-center">{t('instagram.section.title')}</h3>
                <SliderContainer
                    length={this.state.media.length}
                    iteratableObject={this.state.media}
                    extraProps={{'user': this.state.user}}
                    width={16}
                    height={21}
                    visible={3}
                    objectType="instagram"
                />
            </section>
        );
    }

}

export default withTranslation()(Instagram);