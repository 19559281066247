import React from 'react';
import Container from 'react-bootstrap/Container';
import './search.styles.scss';
import VehicleCard from '../../components/vehicle-card/vehicle-card.component';
import { Api } from '../../services/api';
import Budget from '../../components/budget/budget.component';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import Loading from '../../components/loading/loading.component';
import { NavHashLink as NavLink } from 'react-router-hash-link';
import { withTranslation } from 'react-i18next';

class Search extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            availableCategories: [],
            hasAllParams: false,
            editing: true,
            loading: true
        };
    }

    async componentDidMount() {
        await this.setByUrl();
        const {
            origin_location, 
            destination_location, 
            begin_date, 
            end_date
        } = this.props;

        if (
            begin_date !== null &&
            end_date !== null &&
            origin_location !== null &&
            destination_location !== null
        ) {
            let occupation = await this.getOccupation();
            this.setState({
                availableCategories: occupation.occupation,
                hasAllParams: true,
                editing: false

            })
        }
        this.props.step({step: 0});
        this.setState({loading: false});
  
    }

    async componentDidUpdate(prevProps, prevState) {
        let prevPropsLocation = prevProps.location.search;
        let prevPropsParams = new URLSearchParams(prevPropsLocation);
        let newProps  = this.getQueryParams();

        let newPropsData = {
            'origin_location': newProps.get('origin_location'),
            'destination_location': newProps.get('destination_location'),
            'begin_date': newProps.get('begin_date'),
            'end_date': newProps.get('end_date'),
            'begin_time': newProps.get('begin_time'),
            'end_time': newProps.get('end_time')
        };

        let prevPropsData = {
            'origin_location': prevPropsParams.get('origin_location'),
            'destination_location': prevPropsParams.get('destination_location'),
            'begin_date': prevPropsParams.get('begin_date'),
            'end_date': prevPropsParams.get('end_date'),
            'begin_time': prevPropsParams.get('begin_time'),
            'end_time': prevPropsParams.get('end_time')
        };
        
        let checkData = (
            newPropsData.origin_location != prevPropsData.origin_location || 
            newPropsData.destination_location != prevPropsData.destination_location || 
            newPropsData.begin_date != prevPropsData.begin_date || 
            newPropsData.end_date != prevPropsData.end_date 
        );

        if (checkData) {
            this.setState({loading: true});
            await this.setByUrl();
            const {
                origin_location, 
                destination_location, 
                begin_date, 
                end_date,
                begin_time,
                end_time
            } = this.props;
    
            if (
                begin_date !== null &&
                end_date !== null &&
                begin_time !== null &&
                end_time !== null &&
                origin_location !== null &&
                destination_location !== null
            ) {
                let occupation = await this.getOccupation();
                this.setState({
                    availableCategories: occupation.occupation,
                    hasAllParams: true,
                    editing: false
    
                })
            }
            this.setState({loading: false});
        }
    }

    setByUrl = async () => {
        let params = this.getQueryParams();
        await this.props.setSearch({
            'origin_location': params.get('origin_location'),
            'destination_location': params.get('destination_location'),
            'begin_date': moment(params.get('begin_date')),
            'end_date': moment(params.get('end_date')),
            'begin_time': params.get('begin_time'),
            'end_time': params.get('end_time')
        });

        return true;
    }

    getQueryParams = () => {
        let searchString = this.props.location.search;
        let params = new URLSearchParams(searchString);

        return params;
    }

    getOccupation = async () => {
        try {
            let occupation = await Api.get(`occupation`, {
              params: {
                origin_location: this.props.origin_location,
                destination_location: this.props.destination_location,
                begin_date: this.props.begin_date.format('YYYY-MM-DD') + ' ' + this.props.begin_time,
                end_date: this.props.end_date.format('YYYY-MM-DD')+ ' ' + this.props.end_time,
                begin_time: this.props.begin_time,
                end_time: this.props.end_time,
                exchange_currency: this.props.exchange_currency,
                currency_hash: this.props.currency_hash
              }
            });

            return occupation.data;
        } catch (err) {
            console.log(err);
        }
      };

      proceed = (car_category) => {
        this.props.setCategory({
            car_category: car_category
        });
      }

      render() {
        const {t} = this.props;
        const {availableCategories} = this.state;
        let searchOptions = availableCategories.length == 0 
            ? <div className="empty-search">{t('search.empty')}</div>
            : (
                <ul className="search-grid">
                    {availableCategories.map((category) => {
                        return (
                            <div key={category.id} className="category-container">
                            <NavLink onClick={() => this.proceed({...category})} to={"/budget/extras#header"} scroll={el => el.scrollIntoView({ behavior: 'smooth', block: 'start' })} >
                                <VehicleCard 
                                    car_category={category}
                                    duration={this.props.duration}
                                />
                                </NavLink>
                            </div>
                        )
                    })}
                </ul>
            );
        return (
            <Budget>
                <Loading show={this.state.loading} />
                <section className="search">
                    <Container fluid={true} className="mt-5">
                        <div className="row">
                            {searchOptions}
                        </div>
                    </Container>
                </section>
            </Budget>
        );
    }
}

const mapStateToProps = ({budget, website}) => (
    {
        origin_location: budget.origin_location,
        destination_location: budget.destination_location,
        begin_date: budget.begin_date,
        duration: budget.duration,
        end_date: budget.end_date,
        begin_time: budget.begin_time,
        end_time: budget.end_time,
        exchange_currency: website.exchange_currency,
        currency_hash: website.currency_hash
    }
);

const mapDispatchToProps = dispatch => (
    {
        setSearch: (payload) => dispatch({type: 'SET_SEARCH', payload: payload}),
        setCategory: (payload) => dispatch({type: 'SET_CATEGORY', payload: payload}),
        step: (payload) => dispatch({'type': 'STEP', payload: payload})
    }
);

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withRouter(Search)));