import moment from "moment";
import { updateObject } from '../../utilities/utility';

const initialState = {
  begin_date: moment().add(3, "days"),
  end_date: moment().add(5, "days"),
  begin_time: moment().format('HH:mm'),
  end_time: moment().format('HH:mm'),
  origin_location: '',
  destination_location: '',
  duration: 2,
  car_category: null,
  selectedExtras: [],
  step: 0,
  customer_id: null,
  first_name: '',
  last_name: '',
  phone: '',
  whatsapp: '',
  email: '',
  driver_license: '',
  flight_number: '',
  travel_company: '',
  nationality: '',
  coupon: null,
  final_budget: {}
};

const changeExtras = (state, action) => {
  let currentState = {
      ...state
  };
  const {id, quant} = action.payload;

  if (quant === 0) {
    delete currentState.selectedExtras[id];
  } else {
    currentState.selectedExtras[id] = quant;
  }

  return  updateObject(state, currentState);
}
const setExtras = (state, action) => {
  return updateObject(state, {selectedExtras: action.payload});
}
const setSearch = (state, action) => {
  let { origin_location, destination_location, begin_date, end_date, begin_time, end_time} = action.payload;
  
  
  begin_date = moment(begin_date.format('YYYY-MM-DD') + ' ' + begin_time);
  end_date   = moment(end_date.format('YYYY-MM-DD') + ' ' + end_time);

  begin_date.set({hour:0, minute: 0, second: 0});
	end_date.set({hour:23, minute: 59, second: 59});

  let duration = moment.duration(end_date.diff(begin_date, 'days'), 'days').asDays();

  if (duration === 0) {
		duration = 1;
	}

  let newState = {
    ...state,
    origin_location,
    destination_location,
    begin_date,
    end_date,
    begin_time, 
    end_time,
    duration
  }
  
  return updateObject(state, newState);
}


const setCategory = (state, action) => {
  return updateObject(state, {car_category: action.payload.car_category});
}

const setFinalBudget = (state, action) => {
  return updateObject(state, {final_budget: action.payload});
}

const step = (state, action) => {
  return updateObject(state, {step: action.payload.step});
}

const setCoupon = (state, action) => {
  return updateObject(state, {coupon: action.payload});
};

const removeCoupon = (state, action) => {
  return updateObject(state, {coupon: null});
}

const budgetReducer = (state = initialState, action) => {
    switch ( action.type ) {
        case 'SET_SEARCH': return setSearch(state, action);
        case 'SET_CATEGORY': return setCategory(state, action);
        case 'CHANGE_EXTRAS': return changeExtras(state, action);
        case 'SET_SELECTED_EXTRAS': return setExtras(state, action);
        case 'STEP': return step(state, action);
        case 'REMOVE_COUPON': return removeCoupon(state, action);
        case 'SET_COUPON': return setCoupon(state, action);
        case 'SET_FINAL_BUDGET': return setFinalBudget(state, action);
        default: return state;
    }
}

export default budgetReducer;