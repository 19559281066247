export const SUPPORTED_CURRENCIES = {
  en: { 'name': 'English', 'flag': 'US', 'country': 'United States' },
  esES: { 'name': 'Español', 'flag': 'ES', 'country': 'Latino America' },
  ptBR: { 'name': 'Português', 'flag': 'BR', 'country': 'Brasil' }
};

export const importAll = require =>
require.keys().reduce((acc, next) => {
  acc[next.replace("./", "")] = require(next);
  return acc;
}, {});

export const updateObject = (oldObject, updatedProperties) => {
    return {
      ...oldObject,
      ...updatedProperties
    };
  };
  
  export const DISCOUNT_TYPES = {
      1: '$',
      2: '%'
  };
  
  export const originalState = {
        category: null,
        cities: [],
        citiesMap: [],
        duration: 1,
        destinationName: "",
        editing: true,
        extras: [],
        hasAllParams: false,
        listData: [],
        originName: "",
        pending: true,
        step: 1,
        showModal: false,
        modalChildren: "",
        isLogged: false,
        currentBudget: {
          extras: {},
          coupon: null,
          finalBudget: '',
          client: {}
        }
  }
  