import React from 'react';
import './services.styles.scss';

import { withTranslation } from 'react-i18next';

import ServiceCard from '../../components/service-card/service-card.component';
import ListIcon from '../../components/list-icon/list-icon.component';
import { featured_services, services } from '../../utilities/content/company-content';
import Vantagens from '../../assets/vantagens.png';
import { importAll } from '../../utilities/utility';

const Services = ({t}) => {
  const images = importAll(require.context('../../assets/icons/services', false, /\.(png|jpe?g|svg)$/));
  return (
    <section className="services-section">
        <h2 className="text-center mb-5">{t('services.title')}</h2>
        <div className="container">
            <div className="row align-items-center">
                {featured_services.map((element, index) => {
                    return(
                        <div className="col-md-4">
                            <ServiceCard {...element} iconRef={images[element.icon + '.svg']}/>
                        </div>
                    );
                })}
            </div>
            <div className='row align-items-center'>
                <div className="col-lg-6 col-md-12">
                    <figure className="image is-5by4 align-items-center">
                        <img src={Vantagens} alt="advantages" />
                    </figure>
                </div>
                <div className="col-lg-6 col-md-12">
                    <ul className="fa-ul">
                        {services.map((service, index) => {
                            return(
                                <ListIcon icon={'check'} description={t(service)} className="mb-3 advantages" />
                            );
                        })}
                    </ul>
                </div>
            </div>
        </div>
    </section>
  );
};

export default withTranslation()(Services);
